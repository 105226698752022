// charter-request service...
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';
import { share, take } from 'rxjs/operators';

@Injectable()
export class CharterRequestService {
  private url = document.URL;
  private baseUrl = (this.url.search(/localhost/gi) !== -1 || this.url.search(/wapazewdmlit001champsweb.crb.apmoller.net/gi) !== -1) ? environment.baseUrl + '' : environment.baseUrl;
  public unknownToKnownService = null;
  constructor(
    private http: HttpClient,
    private dropdownContent: DropdownContentService
  ) { }

  public getServices() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/Service');
  }

  public getServiceList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/StringList');
  }

  public getPositionList(id) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/PositionList?strPKID=' + id);
  }

  public getVesselClassList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/VesselClassList');
  }

  public getTradeList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/TradeList');
  }

  public postCharterRequest(data) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/CreateOrUpdate', data);
  }
  public getCharterDetail(id) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/' + id);
  }

  public getCharterSerPos(id) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/StringPosition/' + id);
  }

  public deleteCharterRequest(id) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/Remove/', id);
  }
  // charter request Candidates list
  public getCharterCandidateVessels(id, params) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/CandidateVessels/' + id);
  }
  // charter request Candidates list
  public getUpdatedCharterCandidateVessels(id) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/CandidateVessels/' + id);
  }
  // update Charter Candidate on the Fly
  public updateCharterVesselCandidate(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/VesselCandidate/Update', postObj);
  }
  public getPorts(serviceId: any) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/GetPortListAsync/' + serviceId);
  }
  /*
   * Get API call for candidates ports
   */
  public getCountries() {
    return this.http.get(environment.baseUrl + '/api/CountryManagement/Countries');
  }

  // service call to get bunker info for creating service
  public getBunkerForCharter() {
    return this.http.get(environment.baseUrl + '/api/AverageBunker/BunkerPriceForCharterRequest');
  }
  // service call to create service request from Dummy
  public postCharterServiceRequest(data) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ConvertDummyToCharter', data);
  }

  // service call when Fuel Class is updated
  public updateCandidateDesign(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/UpdateCandidateDesign', postObj);
  }

  public revertRequestToDummy(id) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/RevertToDummy/', id);
  }
  /*
 * Get API call for candidates ports
 */
  public getBrokers(forUpload) {
    return this.http.get(environment.baseUrl + '/api/VesselCandidate/GetBrokerTypes?forUpload=' + forUpload);
  }

  /*
   * Get API call for candidates ports
   */
  public getApprovalData(id) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ManagementApproval/Summary?charterRequestId=' + id);
  }

  /** */
  public postVesselCandidateSelect(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/VesselCandidate/Select/', postObj);
  }

  /** apis for the approval page */
  public getApprovalLetterForCreate(charterId, candidateId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ApprovalLetterForCreate?charterId=' + charterId + '&candidateId=' + candidateId);
  }

  public getApprovalLetter(approvalId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/GetApprovalLetter?approvalId=' + approvalId);
  }

  public postApprovalLetter(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ApprovalLetter/CreateOrUpdate', postObj);
  }
  public getPortsDtl(serviceId, position, dependDate) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetPortDetailsForCreateDummy/' + serviceId + '/' + position + '/' + dependDate);
  }
  public getVesselClass(serviceId, position) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetVesselClassForCreateDummy/' + serviceId + '/' + position);
  }
  public getPhaseInPort(position, date) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetPhaseInPortByPositionId/' + position + '/' + date);
  }

  public getPhaseOutPort(position, date) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetPhaseOutPortByPositionId/' + position + '/' + date);
  }

  public getApprovalLetterList(charterId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/GetApprovalLetterList?charterId=' + charterId);
  }

  public approveLetter(alfPKID) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ApprovalLetterApproved/', alfPKID);
  }

  public declineLetter(postData) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ApprovalLetterDeclined/', postData);
  }

  public renegotiateLetter(alPKID) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ApprovalLetterReNegotiate/', alPKID);
  }

  /** get commercial Contact - not brokers */
  public getCommercialOnwers() {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/GetVesselCommercialOwnerList');
  }

  /** assign to me for responsible block */
  public updateResponsibleBlock(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/ManagementApproval/UpdateResponsibleBlock', postObj);
  }

  /** get Paint List for Compare */
  public getPaintList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/VesselCandidate/GetPaintNameList');
  }
  /** */
  public updatePaintImpact(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/VesselCandidate/UpdatePaintImpact', postObj);
  }
  /** */
  public postVesselCandidateCompare(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/CharterRequest/VesselCandidate/CandidateSelectionForCompare/', postObj);
  }
  /** */
  public getNegotiatingRequestStage(charterId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/RequestStage/' + charterId);
  }
  /** */
  public getOwnerScoreFromOwnerName(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/OwnerBMTInspection', postObj);
  }
  public getVesselRequirementCharter(serviceId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/GetVesselRequirementForCharterAsync/' + serviceId);
  }
  public getReplaceVesselReqCharter(serviceId, vesselId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/GetVesselRequirementsByVesselAsync/' + vesselId + '/' + serviceId);
  }
  /** get ports for unknown sevice and vessel */
  public getPortsForUnknown() {
    return this.http.get(environment.baseUrl + '/api/PortManagement/Ports');
  }
  /** get Offhire score for compare screen */
  public getOffhireScoreOnCompare(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/OffhireScore', postObj);
  }
  /** remove candidates from compare screen */
  public removeCandidateFromCompare(postObj) {
    return this.http.post(environment.baseUrl + '/api/ServiceManagement/RemoveCandidateFromCompare', postObj);
  }
  public postOwnerChangeRequest(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselEvaluation/RecalculateVesselScore', postObj);
  }
  /** get fuel class dropdown list */
  public getFuelClassList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/GetFuelClassList');
  }
   /** Service for Broker & Commission */
 public getBrokerCommission() {
  return this.http.get(environment.baseUrl + '/api/VesselManagement/GetVesselBrokerList').pipe(share());
}
}
