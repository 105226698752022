// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { ImageModalService } from '@cham-services/image-modal/image-modal.service';

@Component({
  selector: 'champs-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ReleaseNotesComponent implements OnInit {
  public selectedTypes = [];
  public filteredNotes = [];
  public step = 0;
  public notes = [
    {
      title: '🎉March 2025 Release!🎉',
      date: 'March 21, 2025',
      notes: [
        {
          types: ['others'],
          changes: 'System Technical Modernization',
          subPoints: [
            { note: 'System modernization initiative aimed at enhancing performance and reliability through technical upgrades.' },
            { note: 'This change is purely technical with no functional impact.' },
          ],
        }
      ]
    },
    {
      title: '🎉July 2024 Release!🎉',
      date: 'July 17, 2024',
      notes: [
        {
          types: ['deployment'],
          changes: 'Deployment Communication Matrix',
          subPoints: [
            { note: 'New dictionary page for creating and managing emails', path: '/dictionary/DeploymentContactMatrix' },
            { note: 'Updated service view excel export layout' },
            { note: 'Email sent date in Deployment communication dashboard', path: '/deployment-dashboard' }
          ],
        },
        {

        }
      ]
    },
    {
      title: '🎉July 2024 Release!🎉',
      date: 'July 11, 2024',
      notes: [
        {
          types: ['finance', 'deployment'],
          changes: 'Changes to reduce impact of ITC mod',
          subPoints: [
            { note: 'Separate block for ITC fixtues in vessel page' },
            { note: 'Excluded ITC fixtures from all reports & dashboards' }
          ],
        },
        {
          types: ['bug-fixes'],
          changes: 'Deployment line popup issue on few services',
        },
        {
          types: ['bug-fixes'],
          changes: 'Expense tab date issue',
        },
        {
          types: ['others'],
          changes: 'Angular migration from 12 to 17 and MDS migration to V2',
        },
      ]
    },
    {
      title: '🎉January End 2024 Release!🎉',
      date: 'January 27, 2024',
      notes: [
        {
          types: ['chartering'],
          changes: 'Chartering fixture validation',
        },
        {
          types: ['deployment'],
          changes: 'Service view',
          path: '/service-view'
        },
        {
          types: ['others'],
          changes: 'Angular migration from 11 to 12',
        }
      ]
    },
    {
      title: '🎉November End 2023 Release!🎉',
      date: 'November 27, 2023',
      notes: [
        {
          types: ['finance'],
          changes: 'ITC Changes',
        },
        {
          types: ['deployment'],
          changes: 'Deployment Communication Export',
        },
        {
          types: ['others'],
          changes: '4 Production Incidents',
        },
        {
          types: ['others'],
          changes: '8 PowerBi Reports in CHAMPS app',
        },
      ]
    },
    {
      title: '🎉Early October 2023 Release!🎉',
      date: 'October 04, 2023',
      notes: [
        {
          types: ['chartering'],
          changes: 'Chartering managers - reminder notification to the approvers',
        },
        {
          types: ['deployment'],
          changes: 'A3 view enhancements',
        },
        {
          types: ['finance'],
          changes: 'Full Bunker reversal - All bunkers in one HS',
        },
        {
          types: ['post-fixture'],
          changes: 'Fixture Recap - Add/Edit/Delete comments',
        },
        {
          types: ['finance'],
          changes: 'Daily HS Tracking Dashboard updates',
        },
        {
          types: ['others'],
          changes: 'Angular Migration from 10 to 11',
        },
        {
          types: ['bug-fixes'],
          changes: 'Production Fixes',
        },

      ]
    },
    {
      title: '🎉September 2023 Release!🎉',
      date: 'September 01, 2023',
      notes: [
        {
          types: ['chartering'],
          changes: 'Chartering requests on the broker file upload and Request approvals',
          subPoints: [
            { note: 'Allowing non critical items' },
            { note: 'Ops Vetting status change' },
            { note: 'Present vessel in Compare candidate' },
            { note: 'Triggering mail once the charter is fully approved' },
          ],
          footnote: 'Note: Reminders will be taken up next. It is not present with this release.'
        },
        {
          types: ['deployment'],
          changes: 'A3 view enhancements',
          subPoints: [
            { note: 'Filter for columns' },
            { note: 'Show location of the vessel' },
            { note: 'Saving the selected filters' },
            { note: 'Export A3 to excel' },
          ],
        },
        {
          types: ['others'],
          changes: 'Kafka to Retina migration',
          subPoints: [
            { note: 'CEP ML fixtures' },
            { note: 'Schedule data' },
            { note: 'Vessel data' },
          ],
        },
        {
          types: ['bug-fixes'],
          changes: 'Production Fixes',
        },
      ]
    },
    {
      title: '🎉Mid-August 2023 Release!🎉',
      date: 'August 08, 2023',
      notes: [
        {
          types: ['deployment'],
          changes: 'Deployment automated communication',
          subPoints: [
            { note: 'Sending automatic email on deployment change to stakeholders.' },
            { note: 'Ability to see changes made by others.' }
          ],
        },
        {
          types: ['deployment'],
          changes: 'Planner page redesign',
          subPoints: [
            { note: 'To show more vessels and ports', image: 'assets/images/RN-Planner_enhancement.png' },
          ],
        },
        {
          types: ['deployment'],
          changes: 'Planner page Cut functionality efficiency - Performance improvement',
        },
        {
          types: ['post-fixture'],
          changes: 'SNOW ID linking to Offhire line',
          image: [{ img: 'assets/images/RN-offhire_SNOWID.png' }]
        },
        {
          types: ['others'],
          changes: 'Angular upgrade from 9 to 10',
        },
        {
          types: ['bug-fixes'],
          changes: 'Production Fixes',
        },
      ]
    },
    {
      title: '🎉July End 2023 Release!🎉',
      date: 'July 27, 2023',
      notes: [
        {
          types: ['finance'],
          changes: 'Bareboat charter/ITC vessel on non danish flag settlement process',
          subPoints: [
            { note: 'New Fixture Type: Internal TC for HS creation. Early release for PO. SO will be taken care later.' },
          ],
        },
        {
          types: ['finance', 'bug-fixes'],
          changes: 'US Comma format in hire statement PDF',
        },
        {
          types: ['bug-fixes'],
          changes: 'Difficulty in selecting Dictionary from side nav',
        },
      ]
    },
    {
      title: '🎉Mid-June 2023 Release!🎉',
      date: 'June 16, 2023',
      notes: [
        {
          types: ['post-fixture'],
          changes: 'Postfixture Endorsement Dashboard',
          path: '/postfixture-dashboard'
        },
        {
          types: ['finance'],
          changes: 'Download HS (II)',
          subPoints: [
            { note: 'Combining all the information in the selected hirestatement' },
            { note: 'PDF version available' },
          ],
        },
        {
          types: ['deployment'],
          changes: 'A3 View',
          image: [{ img: 'assets/images/RN-A3.png' }]
        },
      ]
    },
    {
      title: '🎉Mid-May 2023 Release!🎉',
      date: 'May 20, 2023',
      notes: [
        {
          types: ['others'],
          changes: 'Left navigation bar modified as part of MDS changes and Fleetrisk Vessel score is mapped into Champs.',
          image: [{ img: 'assets/images/RN-SideNav.png' }]
        },
        {
          types: ['post-fixture', 'finance'],
          changes: 'Offhire line Reversal'
        },
        {
          types: ['deployment'],
          changes: 'Deployment Planner page - Floating deployment block',
          image: [{ img: 'assets/images/RN-FloatingDepBlock.png' }]
        },
        {
          types: ['chartering'],
          changes: 'Renaming for the dashboard "New fixture validate" to "Fixture validate"',
        },
        {
          types: ['chartering'],
          changes: 'Chartering dashboard - Notice - Fixture redelivery ranges',
          path: '/chartering-dashboard'
        },
        {
          types: ['post-fixture'],
          changes: 'Operator assignment and Dictionary',
        },
      ]
    },
    {
      title: '🎉April End 2023 Release!🎉',
      date: 'April 27, 2023',
      notes: [
        {
          types: ['deployment'],
          changes: 'Deployment Drydock planning dashboard',
          subPoints: [
            { note: 'New Dry Dock Planning dashboard in Deployment Dashboard.', path: '/deployment-dashboard' },
          ],
        },
        {
          types: ['finance'],
          changes: 'Bunker Deduction - Market Rate & Monthly Deduction'
        },
        {
          types: ['finance', 'post-fixture'],
          changes: '4 Eye Validation on offhire line - Phase II',
          subPoints: [
            { note: 'Separate validation now required for Offhire line and Offhire line bunkers.' },
            { note: '\'Offhire Bunker Prices\' dashboard updated to \'Offhire Line Validation\' with option to view both validations.', path: '/finance-dashboard' },
          ],
        },
        {
          types: ['post-fixture'],
          changes: 'Restriction Dashboard',
          subPoints: [
            { note: 'New Timebound Vessel Restrictions dashboard in Deployment Dashboard to view all fixture restrictions in one place.', path: '/postfixture-dashboard' },
          ],
        },
        {
          types: ['deployment'],
          changes: 'Deployment block Enhancement',
          subPoints: [
            { note: 'No validation checks on past deployment line.' },
            { note: 'Small tick to indicate previously selected proforma when updating deployment with multiple proformas.' },
            { note: 'Various performance imporvements.' },
          ],
        },
        {
          types: ['others'],
          changes: 'Quick wins - Enhancement',
          subPoints: [
            { note: 'Opportunity List (II) : \'Available + days\' logic update', path: '/deployment-dashboard' },
          ],
        },
      ]
    },
    {
      title: '🎉Mid-March 2023 Release!🎉',
      date: 'March 18, 2023',
      notes: [
        {
          types: ['chartering'],
          changes: 'Responsible Dictionary Chartering',
          subPoints: [
            { note: 'To make sure chartering user is able to immediately find their pending task on dashboard.', path: '/chartering-dashboard' },
          ],
        },
        {
          types: ['deployment'],
          changes: 'Vessel Dummy Creation - Enhancement to improve deployment workflow.'
        },
        {
          types: ['post-fixture', 'deployment', 'finance'],
          changes: 'CII and Planning group columns are available in MSTR - vessel details 1&2 reports'
        },
        {
          types: ['post-fixture', 'finance'],
          changes: 'SNOW ID is added to offhireline ',
          image: [{ img: 'assets/images/RN-snowID.png' }],
        },
        {
          types: ['finance'],
          changes: '8 new columns are available in MSTR - KRM Fixture and Option Rates report.'
        },
      ]
    },
    {
      title: '🎉New Year! New Release!🎉',
      date: 'January 5, 2023',
      notes: [
        {
          types: ['deployment', 'post-fixture'],
          changes: 'Deployment Block - New validation on Trade & Port Restriction',
          subPoints: [
            { note: 'Trade Restriction for Chartered Vessels - Vessel deployed ports will be checked against fixture trade restriction.' },
            { note: 'Port Restriction for owned/chartered vessels - Vessel deployed service requirement will be checked against vessel particulars. Validation checks include max LOA, max beam, max draft, max speed, height from keel, AMP, Panama, ITF, Gear, # of reefer plugs' },
          ],
          image: [
            { img: 'assets/images/RN-trade.png', note: 'Figure-1: Deployment block show warning messages with hyperlink to fixture/proforma page' },
          ],
          imageNote: 'Figure-2/3: Newly Designed Vessel Requirement Block and Trade Restriction Block for user to update fixture/proforma vessel requirement',
          colImage: [
            { img: 'assets/images/RN-port1.png' },
            { img: 'assets/images/RN-port2.png' }
          ]
        },
        {
          types: ['deployment'],
          changes: 'Deployment Block - \'Save\' function redesigned',
          subPoints: [
            { note: 'Fixed known issues during \'save\'' },
            { note: 'Improved update & save response time' },
            { note: 'Enabled better warning & error message display' },
          ],
          image: [{ img: 'assets/images/RN-save.png' }],
        },
        {
          types: ['post-fixture', 'chartering', 'deployment'],
          changes: 'CII and Planning group columns are added to vessel details/overview page.',
          subPoints: [
            { note: 'Planning group data is updated through one-time data dump. Subsequent maintenance will be done by users.' },
            { note: 'CII data will be updated monthly through data dump, to align with CII dashboard' },
          ],
          image: [{ img: 'assets/images/RN-CII.png' }],
        },
        {
          types: ['finance'],
          changes: '1 day TC deduction automation relaunched',
          subPoints: [
            { note: 'Fixed known issued for past fixtures' },
            { note: '1 day TC settlement for redelivery vessel process is now fully automated - 1 day TC will be withheld when vessel meet preset redelivery criteria and reimbursed when finance user update “Final sent” field.' },
          ],
        },
        {
          types: ['finance', 'post-fixture'],
          changes: '4 Eye Validation on offhire line - Phase I',
          subPoints: [
            { note: 'Added validation requirement for offhire bunker quantity.' },
            { note: 'Revised warning messages' },
            { note: 'Changed data structure in change log' },
          ],
          image: [{ img: 'assets/images/RN-4 Eye.png' }],
        },
        {
          types: ['deployment', 'post-fixture'],
          changes: 'Quick wins - Removal',
          subPoints: [
            { note: 'Service search: removed proforma ID search function', path: '/services' },
            { note: 'Postfixture dashboard: removed 2 unused dashboards- “BMT alerts”, “Issues”', path: '/postfixture-dashboard' },
          ],
        },
        {
          types: ['others'],
          changes: 'Quick wins - Addition',
          subPoints: [
            { note: 'Vessel search: added search by vessel code function', path: '/vessels', image: 'assets/images/RN-vessel_code.png' },
            { note: 'Opportunity list dashboard: added TEU selection', path: '/deployment-dashboard', image: 'assets/images/RN-TEU_filter.png' },
            { note: 'MSTR “Payment List” report: added fixture type and validation status columns' },
          ],
        },
        {
          types: ['finance'],
          changes: 'Quick wins - Enhancement',
          subPoints: [
            { note: 'Fixture Hirestatement tab: default in descending order' },
          ],
          image: [{ img: 'assets/images/RN-HS_order.png' }],
        },
      ]
    },
  ];
  constructor(private imageModalService: ImageModalService, private router: Router) { }

  public ngOnInit(): void {
    this.filterTypes();
  }

  public setStep(index: number) {
    this.step = index;
  }

  public toggleModal(img?: string) {
    this.imageModalService.showModal(img);
  }

  public navigate(path: string) {
    this.router.navigate([]).then(_result => { window.open(path, '_blank'); });
  }

  public toggleFilter(type: string) {
    if (!this.selectedTypes.includes(type)) {
      this.selectedTypes.push(type);
    } else {
      this.selectedTypes.splice(this.selectedTypes.indexOf(type), 1);
    }
    this.filterTypes();
  }

  public isTypeActive(type: string) {
    return this.selectedTypes.includes(type);
  }

  private filterTypes() {
    if (this.selectedTypes.length === 0 || this.selectedTypes.length === 6) {
      this.filteredNotes = this.notes;
    } else {
      const temp = this.notes.map((note: any) => {
        return {
          ...note, notes: note.notes.filter((note1) => {
            return this.selectedTypes.some(type => note1.types.indexOf(type) !== -1);
          })
        };
      });
      this.filteredNotes = temp;
      this.setStep(this.step);
    }
  }
}
