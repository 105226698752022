// tslint:disable-next-line:file-header
import { I } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  convertToLocalIsoString,
  customMoment,
  MAERSK_DATE_FORMATS,
  setZeroHours,
} from '@cham-components/custom-moment-datepicker/custom-moment-datepicker.component';
import { AlertService } from '@cham-services/alert/alert.service';
import { AuthService } from '@cham-services/authentication/auth/auth.service';
import { SessionTimeoutService } from '@cham-services/common/session-timeout.service';
import { PortService } from '@cham-services/port/port.service';
import { VesselsService } from '@cham-services/vessels/vessels.service';
import { isValidDate } from '@cham-utils/valid-date';

import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
const moment = customMoment || _moment;
@Component({
  selector: 'champs-vessel-deployments-lines',
  templateUrl: './vessel-deployments-lines.component.html',
  styleUrls: ['./vessel-deployments-lines.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAERSK_DATE_FORMATS },
  ],
})
export class VesselDeploymentsLinesComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private dialogRef: MatDialogRef<any>;
  public loading: boolean;
  public isPreviousDeployments: boolean = false;
  public isExtendDummyCondition: boolean = false;
  public editDeployments: boolean = false;
  public isCancelShowPreviousClick: boolean = false;
  public showWarningOverlapping: boolean = false;
  public showPortRangeError: boolean = false;
  public showErrorOverlapping: boolean = false;
  public isVsDepUpdateAuth: boolean = false;
  public isVsDepDeleteAuth: boolean = false;
  public errorInPrevDepLines: boolean;
  public errorInPrevDepLinesMessage: string;
  public showDeploymentFormError = false;
  public showIncorrectDetailError: boolean = false;
  public overlappingExist: boolean = false;
  public showSpecialServicesFlag: boolean = false;
  public showScheduleDateError: boolean;
  public isFinanceUser: boolean = false;
  public showHidePreviousDeploymentLink: any = 'Show previous deployments';
  public pastDeploymentControl = {
    configuredNoOfPreviousYear: 1,
    islastYearOptionChecked: new UntypedFormControl(true),
  };
  public vesselDeploymentFrmGrp: UntypedFormGroup;
  public veslDeployments: any;
  public lastYearVesselPreviousDeploymentsObj: any;
  public vesselPreviousDeploymentsObj: any;
  public showAlertForVesselId = '';
  public veselID: any;
  public vslId: any;
  public vesselDetail: any;
  // errors
  public incorrectDeploymentDetails: any;
  public scheduleDateErrorMsg: any;
  public overlappingErrorMsg: any = [];
  public incorrectDeploymentDetailsNew: any;
  public portRangeErrorMsg: any;
  public warnings: string[] = [];
  public trade_restrictions: any;
  //
  public veselType: any;
  public combineFixArrFromOverview: any[];
  public estDeliveryDateArray: any[];
  public oldestDeliveryDate: any;
  public estReDeliveryDateArray: any[];
  public newestReDeliveryDate: any;
  public numberOfvslDeploymentLine: number;
  public vesselCurrentDeployment: any;
  public vesselUpcomingDeployment: any;
  public vesselPreviousDeployments: any[];
  public lastYearVesselPreviousDeployments: any[];
  public deletedDeploymentLines: any = [];
  public veslDeploymentsObj: any;
  public islastYearOptionChecked: boolean = true;
  public dplDeltedMsg = 'Are you sure to you want to delete this deployment?';
  public emptyMessage = 'Sorry, no records found for vessel deployment';
  public depErrorLineMinDate = moment('2021-07-01').format('YYYY-MM-DD');
  public depErrorLineMinDateString = '2021-07-01';
  public showServicePosition: boolean;
  public extraLoaderPosition: any;
  public isVesslDeplyPositionSelected: boolean;
  public vesselDeploymentStartPort: any;
  public vesselDeploymentEndPort: any;
  public schedulePrfStartPortPKId: any;
  public schedulePrfEndPortPKId: any;
  public vesselDeploymentDropdowns: any;
  public previousDeploymentId: any;
  public deploymentDropdown?: any = {};
  public servicePositionsDetailsLst: any = [];
  public depPositionValid: boolean = true;
  public ifPosnEnteredIsSpcl: boolean = false;
  public errorTextExist: boolean = false;
  public matchedProformaLst = [];
  public selectedServiceDtl: any;
  public checkedProformaDtl: any;
  public posPrfLst: any = [];
  public posPrfStartEndDtLst: any = [];
  public lstofServicesPositions: any[];
  public eventDtl: any;
  public frmGrpControlDtl: any;
  public indexDtl: any;
  public depPositionExists: boolean = true;
  public extraPosEntered: boolean = false;
  public showError: boolean = false;
  public validExtraPos: boolean = false;
  public isMultipleProformasflag: boolean = false;
  public isDepLinesValid = [];
  public isAnyControlModified: boolean = false;
  public preventSave = false;
  public fixtureContractNo: string;
  public fixtureIdForRange: any;
  public sticky: boolean = false;
  public CurrentDepselectedProformaID: any;
  public matchingPrfrmSelectedDtl: any[];
  public errorText: string;
  public vesselDeploymentServices: any = [];
  public deploymentErrorLines: number[] = [];
  public vesselDeploymentReasons: any = [];
  public depStartPortListLineWise: any = [];
  public depEndPortListLineWise: any = [];
  public allPorts: any;
  public overlappingMsg: any;
  // Common Component Inputs
  @Input() public showControl?: boolean;
  @Input() public depItems: any;
  @Input() public isOwnDeploymentType: boolean;
  @Input() public floatingBlockVesselID: any;
  @Output() public reloadPlanner: EventEmitter<any> = new EventEmitter();
  // Component Inputs for dummy vessel
  @Input() public fromVesselListVslId: boolean;
  @Input() public fromVesselListPage: boolean;
  @Input() public fromVesselDetailPage: boolean;
  @Input() public fromCreateDummy: boolean;
  @Input() public editModeForDummy: boolean;
  @Input() public isUserNotAuthToEdit: boolean;
  @Input() public dummyOverlapDetails: any;
  // Component Output for dummy vessel
  @Output() public getServiceChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getDatesChangedforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getPositionChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getStartPortChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getUpdatedNameForDummy: EventEmitter<any> = new EventEmitter();
  @Output() public depControlValueChangeEventsEmiter: EventEmitter<any> = new EventEmitter();
  @Output() public emptyDummyName: EventEmitter<any> = new EventEmitter();

  public columnDef = [
    { name: 'Start date', class: 'common' },
    { name: 'End date', class: 'common' },
    { name: 'Service', class: 'service' },
    { name: 'Position', class: 'position' },
    { name: 'Reason', class: 'reason' },
    { name: 'Comment', class: 'comment' },
    { name: 'Start Port', class: 'common' },
    { name: 'End port', class: 'common' },
  ];
  public edieModeColumnDef = [
    ...this.columnDef.slice(0, 6),
    ...[
      { name: 'Start Port', class: 'startport' },
      { name: 'End port', class: 'endport' },
    ],
  ];
  public elementPosition: any;
  @ViewChild('stickyHeader', { read: ElementRef, static: false }) public stickyHeader: ElementRef;
  @ViewChild('selectProformaDialog', { static: false }) public selectProformaDialog: any;
  @ViewChild('confirmationDialog', { static: false }) public deleteDeploymentDialogue: any;
  public finalPayloadFromRedesign: any;
  public saveAfterDeleteStatus: any;
  public allPortsByService: any;
  public showMoreVisible: boolean = false;
  public totalDepLines: any = 0;
  public vesselDeploymentSpclServices: any = [];

  constructor(
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private sessionTimeoutService: SessionTimeoutService,
    private vesselsService: VesselsService,
    private portsService: PortService
  ) {
    this.vslId = this.route.parent.snapshot.paramMap.get('id');
    route.parent.data.subscribe(({ details }) => {
      this.vesselDetail = details;
    });
    this.vesselDeploymentFrmGrp = this.fb.group({
      vesselDeploymentLines: this.fb.array([]),
    });
  }
  get vesselDeploymentLines(): UntypedFormArray {
    return <UntypedFormArray>this.vesselDeploymentFrmGrp.get('vesselDeploymentLines');
  }
  get isPostFixutreUserAuthorized() {
    return this.auth.roles
      ? this.auth.isRolePresent(this.auth.roles, 'PostFixture') ||
          this.auth.isRolePresent(this.auth.roles, 'Chartering')
      : false;
  }
  @HostListener('window:scroll', ['$event'])
  public handleScroll() {
    if (this.editDeployments && this.vesselDeploymentLines.controls.length > 10) {
      this.sticky = window.pageYOffset >= this.elementPosition ? true : false;
      this.changeDetectorRef.markForCheck();
    } else {
      if (this.isPreviousDeployments) {
        this.sticky = window.pageYOffset >= this.elementPosition ? true : false;
        this.changeDetectorRef.markForCheck();
      }
    }
  }
  @HostListener('window:mousemove') public refreshUserState() {
    clearTimeout(this.sessionTimeoutService.userActivity);
    if (!(this.isCancelShowPreviousClick || this.vesselDeploymentFrmGrp.invalid)) {
      this.sessionTimeoutService.setTimeout();
    }
  }
  public ngOnInit(): void {
    this.vslId = this.floatingBlockVesselID ? this.floatingBlockVesselID : this.vslId;
    if (!this.isOwnDeploymentType) {
      this.vesselDetail = this.depItems;
      this.veselType = this.depItems.vcType;
    }
    this.vesselsService.data.subscribe(async (data) => {
      this.combineFixArrFromOverview = await data;
      // To retrieve oldest estimated delivery rate for charter type
      if (data !== '') {
        this.estDeliveryDateArray = data.map((e) => e.fixDelivDate || e.fixEstimatedDate);
        const estDeliveryDateArray = this.estDeliveryDateArray;
        this.oldestDeliveryDate = estDeliveryDateArray.reduce((date2, date1) =>
          Date.parse(date1) < Date.parse(date2) ? date1 : date2
        );
        // To retrieve newest estimated redelivery rate for charter type
        this.estReDeliveryDateArray = data.map((e) => e.fixActRedelivDate || e.fixEstRedelivDate);
        const estReDeliveryDateArray = this.estReDeliveryDateArray;
        this.newestReDeliveryDate = estReDeliveryDateArray.reduce((date1, date2) =>
          Date.parse(date1) > Date.parse(date2) ? date1 : date2
        );
      }
    });

    this.isVsDepUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.isVsDepDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'delete');
    this.isFinanceUser = this.isOwnDeploymentType
      ? this.auth.userProfile.roles.includes('Finance') ||
        this.auth.userProfile.roles.includes('PostFixture') ||
        this.auth.userProfile.roles.includes('FinanceL3') ||
        this.auth.userProfile.roles.includes('Chartering')
      : this.auth.userProfile.roles.includes('Finance');
    this.GetPortsOnLoad(false);
  }
  public InitialLoadData() {
    setTimeout(() => {
      this.loadServiceAndDplyReasonList();
      this.isCancelShowPreviousClick = true;
      if (this.vslId) {
        this.fromVesselListVslId = this.fromVesselListVslId ? this.fromVesselListVslId : this.vslId;
        if (this.isOwnDeploymentType) {
          this.loadCurrUpcomPrevDeploymentsOwn(this.fromVesselListVslId);
        } else {
          this.loadCurrUpcomPrevDeploymentsRelet(this.fromVesselListVslId);
        }
      }

      // Extend To Dummy Condition
      if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselDetailPage) {
        this.extendDummyDetails(this.fromVesselListVslId);
      }
      if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselListPage) {
        this.loaddummyDeployments(this.fromVesselListVslId);
      } else {
        if (this.fromCreateDummy) {
          this.loadDummyMode();
        }
      }
    }, 1);
    if (this.router.url.includes('extenddummy')) {
      this.isExtendDummyCondition = true;
    } else {
      this.isExtendDummyCondition = false;
    }
    this.alertService.alertVesselId.subscribe((res) => {
      this.showAlertForVesselId = res;
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  public ngOnChanges() {
    if (this.dummyOverlapDetails) {
      this.displayDetails(this.dummyOverlapDetails);
    }
  }

  public loadDummyMode() {
    this.editDeployments = !this.editDeployments;
    this.addNewDeploymentLine(null);
  }
  public extendDummyDetails(vslId) {
    const fixEstRedelivDate = localStorage.getItem('this.fixEstRedelivDate');
    const depPosition = Number(localStorage.getItem('this.depPosition'));
    const depStringID = Number(localStorage.getItem('this.depStringID'));

    // this.vesselsService.getVMD(vslId)
    // .pipe(distinctUntilChanged())
    // .subscribe((vesselDetail: Response) => {
    //   this.vesselDetail = vesselDetail;

    const extendedDummy = this.fb.group({
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: depStringID || null,
      depPosition: depPosition || null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: moment(fixEstRedelivDate),
      depEndDate: null,
      depReasonCodeID: null,
      depComments: '',
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,
      durationFixtureEndDate: null,
      vesselOwnType: '',
      depVesselCodeId: 0,
      setDepEndDateErrorCSS: false,
      isMultipleProformas: false,
      depProformaID: null,
      isPastdepLine: false,
      depPastStartPortId: null,
      depPastEndPortId: null,
    });
    this.vesselDeploymentLines.push(extendedDummy);
    const obj = { depStringID: depStringID, depBeginDate: fixEstRedelivDate };
    this.loadPortsByService(obj, extendedDummy, 0);

    this.controlsValueChanges();
    // });
  }
  public loaddummyDeployments(vslId?) {
    this.vesselsService
      .getDummyDetails(vslId)
      .pipe(take(1), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(async (response: any) => {
        const vesselDeployments = response.dummyVesselDetails.deployments;
        if (vesselDeployments) {
          this.veslDeployments = await [
            ...vesselDeployments['currentDeployment'],
            ...vesselDeployments['upcomingDeployment'],
          ].filter((depLines) => !depLines.depIsDeleted);
          this.numberOfvslDeploymentLine = this.veslDeployments.length;
          this.vesselCurrentDeployment =
            vesselDeployments['currentDeployment'] && vesselDeployments['currentDeployment'].length
              ? vesselDeployments['currentDeployment'][0]
              : null;
          // if (this.vesselCurrentDeployment != null) {
          //   const valCurrent = this.vesselCurrentDeployment.maerskAllocationOnServiceDto;
          //   if (valCurrent != null) {
          //     if (
          //       valCurrent.mlmtCap === 0 ||
          //       valCurrent.mlTeuCap === 0 ||
          //       valCurrent.totalMTCap === 0 ||
          //       valCurrent.totalTeuCap === 0
          //     ) {
          //       this.vsaFlagCur = ' ';
          //     } else {
          //       const teuPercent = Number((valCurrent.mlTeuCap / valCurrent.totalTeuCap) * 100).toFixed(0);
          //       const mlPercent = Number((valCurrent.mlmtCap / valCurrent.totalMTCap) * 100).toFixed(0);
          //       if (+teuPercent < 100 || +mlPercent < 100) {
          //         this.vsaFlagCur = '(VSA) ';
          //       } else {
          //         this.vsaFlagCur = ' ';
          //       }
          //     }
          //   }
          // }

          this.vesselUpcomingDeployment =
            vesselDeployments['upcomingDeployment'] && vesselDeployments['upcomingDeployment'].length
              ? vesselDeployments['upcomingDeployment']
              : null;
          if (this.vesselUpcomingDeployment != null) {
            this.vesselUpcomingDeployment.map((e) => {
              const valUpcomming = e.maerskAllocationOnServiceDto;
              if (valUpcomming != null) {
                if (
                  valUpcomming.mlmtCap === 0 ||
                  valUpcomming.mlTeuCap === 0 ||
                  valUpcomming.totalMTCap === 0 ||
                  valUpcomming.totalTeuCap === 0
                ) {
                  e.vsaFlagUp = ' ';
                } else {
                  const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
                  const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
                  if (+teuPercent < 100 || +mlPercent < 100) {
                    e.vsaFlagUp = '(VSA) ';
                  } else {
                    e.vsaFlagUp = ' ';
                  }
                }
              }
            });
          }
          this.vesselPreviousDeployments =
            vesselDeployments['previousDeployment'] && vesselDeployments['previousDeployment'].length
              ? vesselDeployments['previousDeployment']
              : null;
        }
        this.totalDepLines =
          vesselDeployments['currentDeployment'].length +
          vesselDeployments['upcomingDeployment'].length +
          vesselDeployments['previousDeployment'].length;

        if (this.veslDeployments && this.isVsDepUpdateAuth) {
          if (this.isPostFixutreUserAuthorized) {
            this.editDeployments = true;
            this.toogleEditMode();
          } else {
            this.loadDefaultEditMode();
          }
        }
      });

    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe(async (controlValue) => {
      if (controlValue) {
        this.lastYearVesselPreviousDeployments = await this.lastYearPreviousDeployments(controlValue);
        this.lastYearVesselPreviousDeploymentsObj = this.lastYearVesselPreviousDeployments
          ? JSON.parse(JSON.stringify(this.lastYearVesselPreviousDeployments))
          : [{}];
      }
    });
    if (typeof this.veslDeployments !== 'undefined') {
      this.veslDeploymentsObj = JSON.parse(JSON.stringify(this.veslDeployments));
    }
  }
  public loadCurrUpcomPrevDeploymentsOwn(vslId?) {
    this.vesselsService
      .getVesselDeployments(vslId)
      .pipe(take(1), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(async (vesselDeployments: Response) => {
        if (vesselDeployments) {
          this.depItems = await vesselDeployments['allDeployments'][0];
          this.SetDeploymentData();
          this.SetLastYearDeploymentLines();
        }
      });
  }
  public loadCurrUpcomPrevDeploymentsRelet(vslId?) {
    // TODO - we can get list filter from APi itself
    this.SetDeploymentData();
    this.SetLastYearDeploymentLines();
  }
  public IsPrevDepAvaiable(date: any) {
    const depLines = this.vesselPreviousDeployments.filter(
      (o) => new Date(o.depEndDate).valueOf() < new Date(date).valueOf()
    );
    return depLines.length > 0 ? true : false;
  }
  public lastYearPreviousDeployments(v?) {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - this.pastDeploymentControl.configuredNoOfPreviousYear);
    const currentUpcommngDeployments = this.veslDeployments;
    const islastYearData =
      this.vesselPreviousDeployments != null
        ? [
            ...this.vesselPreviousDeployments.filter(
              (o) => new Date(o.depEndDate).getFullYear() >= currentDate.getFullYear()
            ),
            ...currentUpcommngDeployments,
          ]
        : currentUpcommngDeployments != null
        ? [...currentUpcommngDeployments]
        : null;
    return islastYearData != null ? (islastYearData.length !== 0 ? islastYearData : null) : null;
  }
  public toogleEditMode() {
    this.editDeployments = !this.editDeployments;
    if (this.fromCreateDummy) {
      this.editDeployments = true;
    }
    // Assign All vessel deployment to new object
    // this.veslDeploymentsObj =
    //   this.veslDeployments != null && this.veslDeployments.length > 0
    //     ? JSON.parse(JSON.stringify(this.veslDeployments))
    //     : null;

    // const addCurrentUpcommng =
    //   this.vesselPreviousDeployments != null ? [...this.vesselPreviousDeployments, ...this.veslDeployments] : null;

    // this.vesselPreviousDeploymentsObj =
    //   this.vesselPreviousDeployments != null
    //     ? JSON.parse(JSON.stringify(addCurrentUpcommng))
    //     : this.veslDeploymentsObj
    //     ? this.veslDeploymentsObj
    //     : null;
    // if (this.editDeployments) {
    //   this.createDeploymentForm();
    // }
    // if (this.editDeployments && !this.isPreviousDeployments) {
    //   this.createDeploymentForm(this.veslDeploymentsObj);
    // }
    // if (this.editDeployments && this.isPreviousDeployments && !this.islastYearOptionChecked) {
    //   this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
    // }
    // if (this.editDeployments && this.isPreviousDeployments && this.islastYearOptionChecked) {
    //   this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
    // } else {
    //   this.deletedDeploymentLines = [];
    // }

    this.veslDeploymentsObj = this.veslDeployments?.length > 0 ? [...this.veslDeployments] : null;

    this.vesselPreviousDeploymentsObj = this.vesselPreviousDeployments?.length
      ? [...this.vesselPreviousDeployments, ...this.veslDeployments]
      : this.veslDeploymentsObj;
    if (this.editDeployments) {
      this.createDeploymentForm();
      if (!this.isPreviousDeployments) {
        this.createDeploymentForm(this.veslDeploymentsObj);
        return;
      } else if (!this.islastYearOptionChecked) {
        this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
        return;
      } else {
        this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
        return;
      }
    }
    this.deletedDeploymentLines = [];
  }
  public createDeploymentForm(depValues: any = null, lastDeploymentLine?) {
    // const fixEstDelivDate = this.combineFixArrFromOverview.length > 0 ? this.oldestDeliveryDate : null;
    const fixEstRedelivDate = localStorage.getItem('this.fixEstRedelivDate');
    let fromFixture = localStorage.getItem('isFromFixture');

    if (depValues) {
      // let vesselDeploymentFrmGrpArr = this.vesselDeploymentLines;
      // vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(vesselDeploymentFrmGrpArr);
      // tslint:disable-next-line:prefer-const
      let vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(this.vesselDeploymentLines);
      vesselDeploymentFrmGrpArr.reset();
      if (depValues && depValues.length) {
        // looping each deployment line
        [...depValues].forEach((depValue, index) => {
          const item = this.fb.group({
            depPKID: depValue.depPKID,
            depVesselID: this.vslId,
            depStringID: depValue.depStringID,
            depPosition: [
              depValue.extraPosition
                ? depValue.depPosition + 'X'
                : !depValue.extraPosition
                ? depValue.depPosition
                : null,
              [Validators.required],
            ],
            depStartPortID: !depValue.isSpecial ? depValue.depStartPortID : null,
            depEndPortID: !depValue.isSpecial ? depValue.depEndPortID : null,
            depBeginDate: [
              this.isOwnDeploymentType
                ? fromFixture === 'true'
                  ? moment(fixEstRedelivDate)
                  : convertToLocalIsoString(depValue.depBeginDate, this.fromCreateDummy)
                : convertToLocalIsoString(depValue.depBeginDate, this.fromCreateDummy),
              [Validators.required],
            ],

            depEndDate: isValidDate(depValue.depEndDate)
              ? convertToLocalIsoString(depValue.depEndDate, this.fromCreateDummy)
              : null,
            depReasonCodeID: [depValue.depReasonCodeID ? depValue.depReasonCodeID : null, [Validators.required]],
            depComments: depValue.depComments,
            specialSerivceType: depValue.specialSerivceType ? depValue.specialSerivceType : null,
            unemployedeType: depValue.unemployedeType ? depValue.unemployedeType : null,
            layupType: depValue.layupType ? depValue.layupType : null,
            previousDeploymentId: depValue.previousDeploymentId ? depValue.previousDeploymentId : null,
            depSpecialServiceStartPortID: depValue.depSpecialServiceStartPortID
              ? depValue.depSpecialServiceStartPortID
              : null,
            depSpecialServiceEndPortID: depValue.depSpecialServiceEndPortID
              ? depValue.depSpecialServiceEndPortID
              : null,
            extraPosition: depValue.extraPosition,
            depIsDeleted: depValue.depIsDeleted,
            depIsUpdated: depValue.depIsUpdated,
            isSpecialService: depValue.isSpecial || false,
            isSpecialPosition:
              depValue.isSpecialPosition ||
              depValue.isSpecial ||
              (depValue.depSpecialServiceStartPortID && !depValue.depStartPortID)
                ? true
                : false,
            durationFixture: null,

            durationFixtureEndDate: null,

            depVesselCodeId: depValue.vesselCodeID ? depValue.vesselCodeID : 0,
            vesselOwnType: this.isOwnDeploymentType
              ? this.veselType
                ? this.veselType
                : ''
              : this.depItems.vcType
              ? this.depItems.vcType
              : '',
            // setDepEndDateErrorCSS: false

            setDepEndDateErrorCSS: false,
            depToValidate: depValue.depToValidate,
            isMultipleProformas: false,
            depProformaID: depValue.proformaID,
            isPastdepLine: false,
            depPastStartPortId: depValue.depPastStartPortId,
            depPastEndPortId: depValue.depPastEndPortId,
            depEmailStatus: depValue.depEmailStatus,
            depEmailReason: depValue.depEmailReason
          });
          fromFixture = 'false';
          // console.log(`-----------item`);
          // console.log(item);
          // console.log(`-----------item`);
          vesselDeploymentFrmGrpArr.push(item);
        });
        localStorage.setItem('isFromFixture', 'false');
        if (lastDeploymentLine) {
          this.addNewDeploymentLine(lastDeploymentLine);
        }
        this.populateServiceDetails(depValues, this.vesselDeploymentLines.controls);
        this.controlsValueChanges();
        return;
      }
    } else {
      // let vesselDeploymentFrmGrpArr = this.vesselDeploymentLines;
      // vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(vesselDeploymentFrmGrpArr);
      const vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(this.vesselDeploymentLines);
      vesselDeploymentFrmGrpArr.reset();
      this.addNewDeploymentLine();
    }
  }
  public resetFromGrupControls() {
    this.resetVesselDeploymentFrmGrp(this.vesselDeploymentLines);
  }
  public resetVesselDeploymentFrmGrp(formArray: UntypedFormArray): UntypedFormArray {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    return formArray;
  }
  public addNewDeploymentLine(lastDeploymentLine?, frmGrpControl?) {
    // Eror handling for first deployment line start date
    const fixEstDelivDate =
      this.combineFixArrFromOverview && this.combineFixArrFromOverview.length > 0 ? this.oldestDeliveryDate : null;
    let lastDeploymentlineEndDate;
    // Error handling check on extend and dummy overview page for vessel type

    lastDeploymentlineEndDate =
      lastDeploymentLine != null
        ? isValidDate(lastDeploymentLine.depEndDate)
          ? convertToLocalIsoString(lastDeploymentLine.depEndDate, this.fromCreateDummy)
          : null
        : null;

    const extraLine = this.fb.group({
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: null,
      depPosition: null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: lastDeploymentlineEndDate,
      depEndDate: null,
      depReasonCodeID: null,
      depComments: null,
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,
      durationFixtureEndDate: null,
      vesselOwnType: this.fromCreateDummy ? 'Dummy' : this.veselType,
      depVesselCodeId: this.fromCreateDummy ? 0 : this.veselID,
      setDepEndDateErrorCSS: false,
      //  setDepEndDateErrorCSS: false,
      depToValidate: false,
      isMultipleProformas: false,
      depProformaID: null,
      isPastdepLine: false,
      depPastStartPortId: null,
      depPastEndPortId: null,
      depEmailStatus: 'Not Required',
      depEmailReason: 'No Reason'
    });

    if (!this.fromVesselDetailPage) {
      this.vesselDeploymentLines.push(extraLine);
    }
    return;
  }
  public loadDefaultEditMode() { 
    this.editDeployments = !this.editDeployments;
    if (this.fromCreateDummy) {
      this.editDeployments = true;
    }
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.elementPosition = this.getCurrentElementOffsetTop(this.stickyHeader);
    // this.veslDeploymentsObj =
    //   this.veslDeployments != null && this.veslDeployments.length > 0
    //     ? JSON.parse(JSON.stringify(this.veslDeployments))
    //     : null;
    // const addCurrentUpcommng =
    //   this.vesselPreviousDeployments != null ? [...this.vesselPreviousDeployments, ...this.veslDeployments] : null;
    // this.vesselPreviousDeploymentsObj =
    //   this.vesselPreviousDeployments != null
    //     ? JSON.parse(JSON.stringify(addCurrentUpcommng))
    //     : this.veslDeploymentsObj
    //     ? this.veslDeploymentsObj
    //     : null;
    this.veslDeploymentsObj = this.veslDeployments?.length > 0 ? [...this.veslDeployments] : null;
    this.vesselPreviousDeploymentsObj = this.vesselPreviousDeployments?.length
      ? [...this.vesselPreviousDeployments, ...this.veslDeployments]
      : this.veslDeploymentsObj;

    if (this.pastDeploymentControl.islastYearOptionChecked) {
      this.lastYearVesselPreviousDeploymentsObj = this.lastYearPreviousDeployments();
      this.showLastYearDeploymentData();
    }
    if (this.editDeployments && !this.isPreviousDeployments) {
      const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
      if (this.veslDeploymentsObj != null) {
        if (lastDeploymentLine != null) {
          this.createDeploymentForm(this.veslDeploymentsObj, lastDeploymentLine);
        } else {
          this.createDeploymentForm(this.veslDeploymentsObj);
          this.addNewDeploymentLine(null);
        }
      } else {
        if (
          this.vesselUpcomingDeployment == null &&
          this.vesselCurrentDeployment == null &&
          this.vesselPreviousDeployments != null
        ) {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(lastDeploymentLine);
          if (this.fromCreateDummy) {
            this.getServiceChangeValforDummy.emit(null);
          }
        } else {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(null);
          this.getServiceChangeValforDummy.emit(null);
        }
      }
    } else {
      this.deletedDeploymentLines = [];
    }
  }
  public getCurrentElementOffsetTop(element: ElementRef) {
    const rect = element.nativeElement.getBoundingClientRect();
    return rect.top + window.pageYOffset - document.documentElement.clientTop;
  }
  public showLastYearDeploymentData() {
    const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe((controlValue) => {
      if (this.isPreviousDeployments && controlValue) {
        if (this.lastYearVesselPreviousDeploymentsObj != null) {
          if (lastDeploymentLine != null) {
            this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj, lastDeploymentLine);
          } else {
            this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
          }
          // this.CheckDuplicateDepLineWithPrevDepLines();
        } else {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(lastDeploymentLine);
        }
      }
    });
  }
  public getLastDeploymentLineFromCurrentOrUpcominglines() {
    if (this.vesselUpcomingDeployment != null || this.vesselCurrentDeployment != null) {
      return this.vesselUpcomingDeployment != null
        ? Array.isArray(this.vesselUpcomingDeployment)
          ? this.vesselUpcomingDeployment[this.vesselUpcomingDeployment.length - 1]
          : this.vesselUpcomingDeployment
        : this.vesselCurrentDeployment != null
        ? Array.isArray(this.vesselCurrentDeployment)
          ? this.vesselCurrentDeployment[this.vesselCurrentDeployment.length - 1]
          : this.vesselCurrentDeployment
        : null;
    } else {
      return this.vesselPreviousDeployments != null
        ? Array.isArray(this.vesselPreviousDeployments)
          ? this.vesselPreviousDeployments[this.vesselPreviousDeployments.length - 1]
          : this.vesselPreviousDeployments
        : null;
    }
  }
  // Show & Hide Previous Deployment Lines
  public togglePreviousDeployment() {
    this.ResetDeploymentLineErrors();
    this.isPreviousDeployments = !this.isPreviousDeployments;
    this.isCancelShowPreviousClick = true;
    this.changeDetectorRef.detectChanges();
    if (!this.fromCreateDummy) {
      this.elementPosition = this.getCurrentElementOffsetTop(this.stickyHeader);
    }

    this.isPreviousDeployments
      ? (this.showHidePreviousDeploymentLink = 'Hide previous deployments')
      : (this.showHidePreviousDeploymentLink = 'Show previous deployments');

    if (this.editDeployments && this.isPreviousDeployments) {
      const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
      this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe((controlValue) => {
        if (this.isPreviousDeployments && controlValue) {
          this.resetFromGrupControls();
          this.ResetDeploymentLineErrors();
          if (this.lastYearVesselPreviousDeploymentsObj != null) {
            if (lastDeploymentLine != null) {
              this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj, lastDeploymentLine);
            } else {
              this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
            }
          }
        }
        if (this.isPreviousDeployments && !controlValue) {
          this.resetFromGrupControls();
          // this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
          if (this.vesselPreviousDeploymentsObj != null) {
            if (lastDeploymentLine != null) {
              this.createDeploymentForm(this.vesselPreviousDeploymentsObj, lastDeploymentLine);
            } else {
              this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
            }
            // this.CheckDuplicateDepLineWithPrevDepLines();
          } else {
            if (this.vesselUpcomingDeployment != null || this.vesselCurrentDeployment != null) {
              this.resetFromGrupControls();
              this.addNewDeploymentLine(lastDeploymentLine);
            } else {
              this.resetFromGrupControls();
              this.addNewDeploymentLine(null);
            }
          }
        }
      });
    } else {
      if (this.editDeployments && !this.isPreviousDeployments) {
        this.editDeployments = false;
        this.loadDefaultEditMode();
      }
    }
    const element = <HTMLInputElement>document.getElementById('saveDefault');
    if (element) {
      element.disabled = true;
    }
  }
  // Clear Editing Data while Editing
  public cancelEdit(controls?) { 
    this.showError = false;
    this.preventSave = false;
    this.warnings = [];
    this.deploymentErrorLines = [];
    // this.endDateNotPresent = false;
    this.showIncorrectDetailError = false;
    // this.showBeginDateError = false;
    // this.showBeginTimeError = false;
    // this.showEndDateError = false;
    // this.showEndTimeError = false;
    // this.showRangeInvalid = false;
    // this.showDatesInvalid = false;
    // this.showLocationHidePort = false;
    this.showScheduleDateError = false;
    this.showWarningOverlapping = false;
    this.showErrorOverlapping = false;
    this.showPortRangeError = false;
    this.deletedDeploymentLines = [];
    this.isCancelShowPreviousClick = true;
    // this.saveAfterDelete = true;
    this.overlappingExist = false;
    if (this.editDeployments) {
      if (!this.fromCreateDummy) {
        this.editDeployments = false;
      }

      this.isPreviousDeployments = false;
      this.showHidePreviousDeploymentLink = 'Show previous deployments';
      this.loadDefaultEditMode();
    }
    if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselDetailPage) {
      this.extendDummyDetails(this.fromVesselListVslId);
    }
  }
  // Save the Deployment Lines
  public saveAllDeploymentLines(type) {
    this.RemoveErrorsFromPastdeployments();
    // this.checkDateEquality();
    // this.checkPrevEndDateMin();
    this.checkMinStartDateAndEquality(true);
    this.CheckDuplicateDepLineWithPrevDepLines();
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    if (
      (!this.vesselDeploymentFrmGrp.valid && !this.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.valid) ||
      this.preventSave ||
      this.errorInPrevDepLines
    ) {
      this.showError = true;
      this.spinner.hide();
      return;
    }
    const deletedDeploymentLines = this.deletedDeploymentLines.length > 0 ? this.deletedDeploymentLines : [];
    const payloadData = this.vesselDeploymentFrmGrp.get('vesselDeploymentLines').value;
    const payloadObject = {
      // vesselID: this.vslId != null ? this.vslId : 0,
      vesselID: this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0,
      lstVesselDeplymentDto: [
        ...payloadData.filter(
          (depLines) => depLines.depEndDate != null && depLines.depPosition != null && depLines.depStringID != null
        ),
        ...deletedDeploymentLines,
      ],
    };
    let deleteCount = 0;
    let updateCount = 0;
    let existingDepCount = 0;
    let newDepCount = 0;
    payloadObject.lstVesselDeplymentDto.forEach((element) => {
      if (element.extraPosition) {
        const extraPosValue = element.depPosition.toLowerCase();
        const xPos = extraPosValue.indexOf('x');
        element.depPosition =
          xPos === 1
            ? element.depPosition.slice(0, 1)
            : xPos === 2
            ? (element.depPosition = element.depPosition.slice(0, 2))
            : element.depPosition;
      }
      if (element.depPKID > 0) {
        existingDepCount++;
        if (element.depIsDeleted) {
          deleteCount++;
        }
        if (element.depIsUpdated) {
          updateCount++;
        }
      }
      element.depVesselID = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      if (element.depPKID === 0) {
        newDepCount++;
      }
      // Check is Past date
      const today = new Date();
      const depEndDate = new Date(element.depEndDate);
      if (depEndDate.getTime() < today.getTime()) {
        element[`isPastdepLine`] = true;
      } else {
        element[`isPastdepLine`] = false;
      }
    });
    // No Need of again looping
    // payloadObject.lstVesselDeplymentDto.forEach((element) => {
    // });
    let checkOverlap = true;
    if (newDepCount === 0 && existingDepCount === deleteCount) {
      checkOverlap = false;
    }
    if (updateCount === 0 && deleteCount > 0) {
      this.vesselDeploymentFrmGrp.updateValueAndValidity();

      if (this.vesselDeploymentFrmGrp.invalid) {
        checkOverlap = true;
      } else {
        checkOverlap = false;
      }
    }
    // this.deletedDeploymentLines = [];
    if (
      !this.showErrorOverlapping &&
      !this.showWarningOverlapping &&
      !this.showPortRangeError &&
      checkOverlap &&
      !this.fromCreateDummy &&
      this.fromCreateDummy === undefined
    ) {
      this.incorrectDeploymentDetailsNew = '';
      // Send Only those object which are active dep lines no need to validate past dep lines
      const payloadObject_activeLine = { ...payloadObject };
      payloadObject_activeLine.lstVesselDeplymentDto = payloadObject.lstVesselDeplymentDto.filter((item) => {
        return item.isPastdepLine === false;
      });

      this.vesselsService.saveVesselDeploymentNewVessel(payloadObject_activeLine).subscribe(
        (res) => {
          if (res && res[0] && res[0].isOverlapDeployment) {
            this.overlappingExist = true;
            this.showErrorOverlapping = false;
            this.showWarningOverlapping = true;
            this.showIncorrectDetailError = true;
            const extraPos = res[0].extraPosition ? true : false;

            const warnMessage = {
              vessel_status_id: null,
              vslPKID: res[0].deployedVessels[0].deployedVesselID,
              vessel_name: res[0].deployedVessels[0].deployedVesselName,
              position: res[0].depPosition,
              overlapping_start_date: new DatePipe('en-US').transform(
                res[0].deployedVessels[0].deployedVslBeginDate,
                'dd-MMM-yyyy'
              ),
              overlapping_end_date: new DatePipe('en-US').transform(
                res[0].deployedVessels[0].deployedVslEndDate,
                'dd-MMM-yyyy'
              ),
              extra_position: extraPos,
            };
            const warnMessageArray = [];
            warnMessageArray.push(warnMessage);

            this.overlappingMsg = warnMessageArray;
          } else {
            this.sendVesselDeoplymentPostObject(payloadObject);
          }
        },
        // Warning overlapping implementation
        (errorResponse) => {
          this.displayDetails(errorResponse);
          this.spinner.hide();
        }
      );
    } else {
      this.sendVesselDeoplymentPostObject(payloadObject);
    }
  }
  public RemoveErrorsFromPastdeployments() {
    const depLines = this.vesselDeploymentLines.value;
    for (const [index, item] of depLines.entries()) {
      if (item.isPastdepLine) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depStringID'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depReasonCodeID'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depSpecialServiceEndPortID'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depSpecialServiceStartPortID'].setErrors(null);
      }
    }
    this.vesselDeploymentLines.updateValueAndValidity();
  }

  public sendVesselDeoplymentPostObject(finalPayloadObject?) {
    finalPayloadObject.lstVesselDeplymentDto.forEach((element) => {
      element.isMultipleProformas = this.isMultipleProformasflag;
      element.depBeginDate = setZeroHours(convertToLocalIsoString(new Date(element.depBeginDate)));
      element.depEndDate = setZeroHours(convertToLocalIsoString(new Date(element.depEndDate)));
    });
    const payloadObject_activeLine = { ...finalPayloadObject };
    payloadObject_activeLine.lstVesselDeplymentDto = payloadObject_activeLine.lstVesselDeplymentDto.filter((item) => {
      return item.depIsUpdated === true || item.depIsDeleted === true;
    });

    if (!this.fromCreateDummy) {
      this.clearAlertService();
      this.vesselsService
        .saveVesselDeploymentNew(payloadObject_activeLine)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.deletedDeploymentLines = [];
            this.showError = false;
            this.showIncorrectDetailError = false;
            this.ResetDeploymentLineErrors();
            // this.showBeginDateError = false;
            // this.showBeginTimeError = false;
            // this.showEndDateError = false;
            // this.showEndTimeError = false;
            // this.showRangeInvalid = false;
            // this.showDatesInvalid = false;
            // this.showLocationHidePort = false;
            // Commented below reload for performance fix
            // Uncommented this below code we have not handle some case like when we toggle
            // its creating form with old values
            if (this.floatingBlockVesselID) {
              this.reloadPlanner.emit();
            } else {
              this.router
                .navigateByUrl('/vessels', { skipLocationChange: true })
                .then(() => this.router.navigate(['/vessels/' + this.vslId]));
            }
            if (!this.vslId) {
              this.alertService.alertVesselId.next(this.veselID);
              this.showAlertForVesselId = this.veselID;
              this.alertService.success('Created successfully', true);
            } else {
              this.alertService.alertVesselId.next(this.veselID);
              this.showAlertForVesselId = this.veselID;
              this.alertService.success('Updated successfully', true);
            }

            // this.ngOnInit();
          },
          (errorResponse) => {
            // this.dialogRef.close();
            if (errorResponse && errorResponse instanceof HttpErrorResponse) {
              this.spinner.hide();
              // this.errorKeys = Object.keys(this.vesselDeploymentLines).filter((key) =>
              //   Object.keys(errorResponse.error).includes(key)
              // );
              // this.errorValues = this.errorKeys.map((errorKey) => errorResponse.error[errorKey]);
              const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
              const data = errorResponse.error && errorResponse.error.data ? errorResponse.error.data : null;
              if (error) {
                this.showIncorrectDetailError = true;
                this.incorrectDeploymentDetails = error;
                this.overlappingMsg = typeof error !== 'string' ? error : [];
              }
            }
          }
        );
    } else {
      // TODO Check with Dummy
      this.finalPayloadFromRedesign = finalPayloadObject;
      this.saveAfterDeleteStatus = this.vesselDeploymentFrmGrp.invalid;
      return;
    }
  }
  public ValidatedeploymentForm() {
    let IsInvalid = false;
    let message = 'Invalid Deployment line ';
    for (let index = 0; index < this.vesselDeploymentLines.value.length; index++) {
      const item = this.vesselDeploymentLines.value[index];
      if (
        (item.depStringID === null ||
          item.depBeginDate === null ||
          item.depEndDate === null ||
          item.depReasonCodeID === null ||
          item.depPosition === null ||
          item.depPosition === '') &&
        this.vesselDeploymentLines.value.length !== index + 1
      ) {
        IsInvalid = true;
        message = `${message} ${index + 1}`;
      }
    }
    if (IsInvalid) {
      this.errorInPrevDepLines = true;
      this.errorInPrevDepLinesMessage =
        this.errorInPrevDepLinesMessage == null ? message : `${this.errorInPrevDepLinesMessage},${message}`;
    }
  }
  // get service code for read-only mode
  public getServiceCode(serviceName) {
    let serviceNameAndCode = null;
    if (serviceName) {
      serviceNameAndCode = this.vesselDeploymentServices.filter((e) => {
        if (e.svcName === serviceName) {
          return e.strNameCode;
        }
      });
      if (this.isOwnDeploymentType) {
        for (let i = 0; i < serviceNameAndCode.length; i++) {
          if (serviceNameAndCode[i].svcName === serviceName) {
            return serviceNameAndCode[i].strNameCode;
          }
        }
      } else {
        if (serviceNameAndCode[0].svcName === serviceName) {
          return serviceNameAndCode[0].strNameCode;
        } else {
          return serviceName;
        }
      }
    }
  }
  public isVsDepDeleteAuthCheck() {
    if (this.auth.BYPASS_AUTHENTICATION) {
      return true;
    } else {
      return (
        this.auth.isRolePresent(this.auth.roles, 'Deployment') ||
        this.auth.isRolePresent(this.auth.roles, 'L3') ||
        this.auth.isRolePresent(this.auth.roles, 'FinanceL3')
      );
    }
  }
  public redirectToVslOverview(vessel_status_id, vslPKID) {
    if (vessel_status_id === null || vessel_status_id === '') {
      // vessel

      window.open('/vessels/' + vslPKID, '_blank');
    } else if (vessel_status_id === 3 || vessel_status_id === 2) {
      // Request OR Dummy

      window.open('/vessels/dummy/overview/' + vslPKID, '_blank');
    }
  }
  public navigateToFixOverview(fixtureIdForRange) {
    // fixture
    window.open('/fixtures/' + fixtureIdForRange, '_blank');
  }
  public startDateChng(frmGrpControl?, index?, isFunCall = false) {
    this.isCancelShowPreviousClick = false;

    if (
      isFunCall &&
      frmGrpControl &&
      this.IsPastdeploymentLine(frmGrpControl) &&
      frmGrpControl.controls.depPKID.value !== 0
    ) {
      if (this.fromCreateDummy) {
        this.getDatesChangedforDummy.emit(frmGrpControl.controls.depBeginDate.value);
        //  this.DummyEmitChanges(frmGrpControl, index);
      }
      this.CheckDateOnChanged(frmGrpControl, index);
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
      return;
    }

    this.clearAlertService();
    // this.checkMinStartDate();
    // this.checkDateEquality();
    // this.checkPrevEndDateMin();
    // this.CheckDuplicateDepLineWithPrevDepLines();

    // // To enable or disable Save button on start date click
    // this.displayErrorLines(this.vesselDeploymentFrmGrp);
    // // this.checkMinStartDateAndEquality(true);
    this.checkMinStartDateAndEqualityForm(frmGrpControl, index, true);
    // this.checkMinStartDate();=> Merge into checkMinStartDateAndEquality function
    // this.checkDateEquality();=> Merge into checkMinStartDateAndEquality function
    // this.checkPrevEndDateMin(); Merge into checkMinStartDateAndEquality function
    // this.CheckDuplicateDepLineWithPrevDepLines(); Already Called in above method no need to call again
    // this.vesselDeploymentFrmGrp.updateValueAndValidity();

    if (frmGrpControl.controls.depEndDate.value == null || frmGrpControl.controls.depStringID.value == null) {
      frmGrpControl.setErrors({ invalid: true });
    }

    // let checkGapForChrter = false;
    this.getDatesChangedforDummy.emit(frmGrpControl.controls.depBeginDate.value);
    // Below Code Merge into checkMinStartDateAndEquality function
    // if (index > 0) {
    //   this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
    // }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    // // this.checkMinStartDateAndEquality(true);
    if (frmGrpControl.controls.depBeginDate.invalid) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      this.vesselDeploymentLines.updateValueAndValidity();
      return;
    } else {
      if (this.ValidateCurrentDepWithPrevDepDates(frmGrpControl, index)) {
        return;
      }
    }

    // No Need Check Again that already checked in checkMinStartDateAndEquality
    // if (this.validateGapDeploymentLine(frmGrpControl, index)) {
    //   return;
    // }

    if (this.validateStartDateErrHandlng('', frmGrpControl, index)) {
      return false;
    }

    // why We need to check again begin date as we already check in above ? so commenting
    // if (!isValidDate(frmGrpControl.controls.depBeginDate.value)) {
    //   this.isValidDateUpdateControlValue(frmGrpControl.controls.depBeginDate, null);
    //   return false;
    // }
    // Begin- code for Charter Vsl type Scenario erro handling

    // End
    this.showScheduleDateError = false;
    this.showMoreVisible = false;
    const strDate = moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD');
    const endDate =
      frmGrpControl.controls.depEndDate.value !== null
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

    const selectedService = frmGrpControl.controls.depStringID.value;
    const selectedDateTime = strDate ? strDate + 'T' + '00:00' : null;
    const selectedEndDateTime = endDate ? endDate + 'T' + '00:00' : null;
    const selectedposition = frmGrpControl.controls.depPosition.value;
    // Normal Position -> Service Avaiable -->
    if (selectedService && selectedDateTime != null && !frmGrpControl.controls.isSpecialPosition.value) {
      this.vesselsService
        .getPortsByServiceByPosition(selectedService, selectedDateTime, selectedEndDateTime, selectedposition)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.allPortsByService =  response;
            // Logic for Extra position containing x or X
            const positionSelected = this.ValidateExtraPositionSelection(frmGrpControl);
            // this.vesselDeploymentStartPort = [];
            if (positionSelected && positionSelected > 0) {
              this.isVesslDeplyPositionSelected = true;
              this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.position === positionSelected);
            } else {
              this.isVesslDeplyPositionSelected = false;
              this.vesselDeploymentStartPort = response.portDateTime;
            }
            this.depStartPortListLineWise[index] = {};
            this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort;
            if (positionSelected > 0) {
              // this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(this.vesselDeploymentStartPort[0].schedulePortId);
              let startPortToPopulate: any;
              const startDateBeforePort = new Date(frmGrpControl.controls.depBeginDate.value);
              // startPortToPopulate = response.portDateTime.filter(k => positionSelected === k.position)

              startPortToPopulate = response.portDateTime.filter(
                (k) =>
                  positionSelected === k.position &&
                  startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
              );

              if (startPortToPopulate.length) {
                frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
                this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;
                frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
              }

              if (this.fromCreateDummy && index === 0) {
                this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
              }
            }
            this.checkAdjacentLinesForSpcl(frmGrpControl, index);
          },
          (errorResponse) => {
            this.HandleSchedulesNotExistForPastDates(errorResponse, index, frmGrpControl);
            // this.showScheduleDateError = true;
            // const msg = errorResponse.error.error;
            // this.scheduleDateErrorMsg = msg;
            // this.depEndPortListLineWise[index] = {};
            // this.depEndPortListLineWise[index].ports = [];
            // this.depStartPortListLineWise[index] = {};
            // this.depStartPortListLineWise[index].ports = [];
            // this.spinner.hide();
          }
        );
    } else if (frmGrpControl.controls.isSpecialPosition.value) {
      if (!this.IsSpecialPositionChanged(frmGrpControl, frmGrpControl.controls.depPosition.value)) {
        this.loadPorts(frmGrpControl, index);
      }
    }

    if (frmGrpControl.controls.depPosition.value && frmGrpControl.controls.depStringID.value) {
      this.verifyMultiplePrfExists('', frmGrpControl, index);
    }
    this.DummyEmitChanges(frmGrpControl, index);
  }
  public DummyEmitChanges(frmGrpControl, index) {
    if (frmGrpControl.controls.depEndDate.value == null || frmGrpControl.controls.depStringID.value == null) {
      const emitObj = {
        isInvalid: true,
        isModified: this.isAnyControlModified,
        isPristine: this.vesselDeploymentLines.pristine,
        isDirty: this.vesselDeploymentLines.dirty,
        controlIndex: index,
        isdeplineEmpty: true,
      };
      this.depControlValueChangeEventsEmiter.emit(emitObj);
    }
  }
  public endDateChng(frmGrpControl?, index?, isLastDeploymentLine?, isFunCall = false) {
    this.isCancelShowPreviousClick = false;
    const isPastdepLine = this.IsPastdeploymentLine(frmGrpControl);
    // console.log('endDateChng');

    if (isFunCall && frmGrpControl && isPastdepLine && frmGrpControl.controls.depPKID.value !== 0) {
      this.getDatesChangedforDummy.emit(frmGrpControl.controls.depEndDate.value);
      this.SetNextdeploymentLineStartDate(frmGrpControl, index);
      if (isLastDeploymentLine) {
        const lastDepl = {
          depEndDate: frmGrpControl.controls.depEndDate.value,
        };
        this.addNewDeploymentLine(lastDepl);
        this.addLastDeploymentValidators(frmGrpControl.controls['depPosition']);
        this.addLastDeploymentValidators(frmGrpControl.controls['depReasonCodeID']);
        this.ResetErrorForPastDeployments(frmGrpControl);
      }
      this.CheckDateOnChanged(frmGrpControl, index);
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
      return;
    }
    // console.log(frmGrpControl);
    this.clearAlertService();
    // this.checkMinStartDate();=> Merge into checkMinStartDateAndEquality function
    // this.checkDateEquality();=> Merge into checkMinStartDateAndEquality function
    // this.checkMinStartDateAndEquality();
    this.checkMinStartDateAndEqualityForm(frmGrpControl, index, true);
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    // Below Code No Use Everywhere we set value as false
    // TODO : Remove => setDepEndDateErrorCSS remove from HTML As well
    // if (frmGrpControl) {
    //   frmGrpControl.controls.setDepEndDateErrorCSS.setValue(false);
    // }
    // TODO - Validate with preetham or neetu
    this.controlsValueChanges();
    this.getDatesChangedforDummy.emit(frmGrpControl.controls.depEndDate.value);
    // const checkGapForChrter = false; // No Where Used

    if (frmGrpControl.controls.depEndDate.invalid) {
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      return;
    } else if (
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].valid &&
      this.ValidateCurrentDepWithPrevDepDates(frmGrpControl, index)
    ) {
      return;
    }
    if (this.validateEndDateErrHandlng('', frmGrpControl, index)) {
      return;
    }
    // if (!isValidDate(frmGrpControl.controls.depEndDate.value)) {
    //   this.isValidDateUpdateControlValue(frmGrpControl.controls.depEndDate, null);
    //   return false;
    // }
    let vslVesselType;
    // Error handling check on extend and dummy overview page for vessel type
    if (this.fromCreateDummy === true) {
      vslVesselType = localStorage.getItem('this.vesselDetail.vslVesselType');
    }
    if (this.ValidateNextDepWithCurrentDepDates(frmGrpControl, index)) {
      return;
    }
    // Moved function to call after depBeginDate is set
    // this.CheckDuplicateDepLineWithPrevDepLines();
    this.showScheduleDateError = false;
    this.showWarningOverlapping = false;
    this.warnings = [];
    this.showErrorOverlapping = false;
    this.showPortRangeError = false;

    const endDate =
      frmGrpControl.controls.depEndDate.value !== null
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;
    const beginDate =
      frmGrpControl.controls.depBeginDate.value !== null
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;
    const selectedService = frmGrpControl.controls.depStringID.value;
    const selectedDateTime = endDate ? endDate + 'T' + '00:00' : null;
    const selectedposition = frmGrpControl.controls.depPosition.value;
    // Editing Last Line then add new next Dep line with
    // TODO : Validate below Logic
    if (isLastDeploymentLine) {
      const lastDepl = {
        depEndDate: frmGrpControl.controls.depEndDate.value,
      };
      this.addNewDeploymentLine(lastDepl);
      this.addLastDeploymentValidators(frmGrpControl.controls['depPosition']);
      this.addLastDeploymentValidators(frmGrpControl.controls['depReasonCodeID']);
    }
    if (selectedService && selectedDateTime != null && !frmGrpControl.controls.isSpecialPosition.value) {
      this.vesselsService
        .getPortsByServiceByPosition(selectedService, beginDate, endDate, selectedposition)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.allPortsByService =  response;

            // Logic for Extra position containing x or X
            const positionSelected = this.ValidateExtraPositionSelection(frmGrpControl);
            // this.vesselDeploymentEndPort = [];
            if (positionSelected && positionSelected > 0) {
              this.isVesslDeplyPositionSelected = true;
              this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.position === positionSelected);
              this.depEndPortListLineWise[index] = {};
              this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort;
              if (this.vesselDeploymentEndPort.length === 0) {
                frmGrpControl.controls.depEndPortID.setValue('');
                this.showScheduleDateError = true;
                const msg = 'Schedules do not exist for the selected end date ';
                this.scheduleDateErrorMsg = msg;
              }
            } else {
              this.isVesslDeplyPositionSelected = false;
              this.vesselDeploymentEndPort = response.portDateTime;
              this.depEndPortListLineWise[index] = {};
              this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort;
              if (positionSelected > 0) {
                let endPortToPopulate: any;
                const endDateBeforePort = new Date(
                  new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
                );
                endPortToPopulate = response.portDateTime.filter(
                  (k) =>
                    positionSelected === k.position &&
                    endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                );
                if (endPortToPopulate.length) {
                  frmGrpControl.controls.depEndPortID.setValue(
                    endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
                  );
                  this.schedulePrfEndPortPKId = endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;
                  frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                  frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                }
                this.checkAdjacentLinesForSpcl(frmGrpControl, index);
              }
            }
          },
          (errorResponse) => {
            // Error handling for end port and end date when schedule doesn;t exist.
            this.HandleSchedulesNotExistForPastDates(errorResponse, index, frmGrpControl);

            // this.showScheduleDateError = true;
            // const msg = errorResponse.error.error;
            // this.scheduleDateErrorMsg = msg;
            // this.depEndPortListLineWise[index] = {};
            // this.depEndPortListLineWise[index].ports = [];
            // this.depStartPortListLineWise[index] = {};
            // this.depStartPortListLineWise[index].ports = [];
            // frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
            // frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
            // this.spinner.hide();
          }
        );
      frmGrpControl.controls['depEndPortID'].setErrors(null);
      frmGrpControl.controls['depEndDate'].setErrors(null);
    } else if (frmGrpControl.controls.isSpecialPosition.value) {
      if (!this.IsSpecialPositionChanged(frmGrpControl, frmGrpControl.controls.depPosition.value)) {
        this.loadPorts(frmGrpControl, index);
      }
    }
    this.validateGapDeploymentLineEndDate(frmGrpControl, index);

    if (frmGrpControl.controls.depPosition.value && frmGrpControl.controls.depStringID.value) {
      this.verifyMultiplePrfExists('', frmGrpControl, index);
    }
  }
  public ValidateCurrentDepWithPrevDepDates(frmGrpControl, index) {
    let isInvalid = false;
    const BeginDate =
      typeof frmGrpControl?.controls.depBeginDate.value === 'string'
        ? moment(frmGrpControl.controls.depBeginDate.value)
        : frmGrpControl?.controls.depBeginDate.value;

    const EndDate =
      typeof frmGrpControl?.controls.depEndDate.value === 'string'
        ? moment(frmGrpControl.controls.depEndDate.value)
        : frmGrpControl?.controls.depEndDate.value;

    if (BeginDate && EndDate && BeginDate.format('YYYY-MM-DD') > EndDate.format('YYYY-MM-DD')) {
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      // this.isCancelShowPreviousClick = true;
      isInvalid = true;
    } else {
      const previousIndexDepLine = this.getValue(index - 1);
      if (previousIndexDepLine) {
        const prevEndDate =
          typeof previousIndexDepLine['controls'].depEndDate.value === 'string'
            ? moment(previousIndexDepLine['controls'].depEndDate.value)
            : previousIndexDepLine['controls'].depEndDate.value;

        // Check Previous End Date  with begin date
        // const prevEndDate = moment(previousIndexDepLine['controls'].depEndDate.value);
        // const formattedPrevEndDate = prevEndDate.isValid() ? prevEndDate.format('YYYY-MM-DD') : null;

        if (index > 0 && BeginDate && prevEndDate.format('YYYY-MM-DD') > BeginDate.format('YYYY-MM-DD')) {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
          this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
          isInvalid = true;
        } else {
          // this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
          this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
        }
      }
    }

    return isInvalid;
  }
  public ValidateNextDepWithCurrentDepDates(frmGrpControl, index) {
    let isInValid = false;
    const nextIndexDepLine = this.getValue(index + 1);
    if (nextIndexDepLine) {
      nextIndexDepLine['controls'].depBeginDate.setValue(frmGrpControl.controls.depEndDate.value);
      /*bug 345942 fix*/
      nextIndexDepLine['controls']['depIsUpdated'].setValue(true);
      let invalid = false;
      const BeginDate = frmGrpControl.controls.depEndDate.value !== null && frmGrpControl.controls.depEndDate.value;
      const EndDate =
        nextIndexDepLine['controls'].depEndDate.value !== null && nextIndexDepLine['controls'].depEndDate.value;
      if (BeginDate && EndDate) {
        invalid = new Date(BeginDate).valueOf() > new Date(EndDate).valueOf();
      }
      if (invalid) {
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
        isInValid = true;
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depEndDate'].setErrors(null);
      }
      this.controlsValueChanges();
    }
    return isInValid;
  }
  public SetNextdeploymentLineStartDate(frmGrpControl, index) {
    const nextIndexDepLine = this.getValue(index + 1);
    if (nextIndexDepLine) {
      nextIndexDepLine['controls'].depBeginDate.setValue(frmGrpControl.controls.depEndDate.value);
      nextIndexDepLine['controls']['depIsUpdated'].setValue(true);
    }
  }
  public validateGapDeploymentLineEndDate(control?, index?) {
    if (
      index !== this.vesselDeploymentLines.value.length - 1 &&
      isValidDate(this.vesselDeploymentLines.value[index + 1].depBeginDate)
    ) {
      const controlEndDateDeployment = new Date(new DatePipe('en-US').transform(control.value.depEndDate, 'dd-MMM-yy'));
      const nextDeployStartDate = new Date(
        new DatePipe('en-US').transform(this.vesselDeploymentLines.value[index + 1].depBeginDate, 'dd-MMM-yy')
      );

      if (controlEndDateDeployment.getTime() > nextDeployStartDate.getTime()) {
        // this.addLastDeploymentValidators(this.vesselDeploymentLines.controls[index]['controls']['depBeginDate']);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
    }

    this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
  }
  public addLastDeploymentValidators(control?) {
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
    this.vesselDeploymentLines.updateValueAndValidity();
    this.controlsValueChanges();
  }

  public IsPastdeploymentLine(frmGrpControl, isInsertDepCall = false) {
    // Update =>  when its a special position
    if (!frmGrpControl?.controls) {
      return false;
    }
    if (frmGrpControl.dirty && !isInsertDepCall) {
      frmGrpControl['controls']['depIsUpdated'].setValue(true);
      this.isCancelShowPreviousClick = false;
    }
    if (frmGrpControl?.controls.depEndDate.value != null && frmGrpControl.controls.depBeginDate.value != null) {
      const today = new Date();
      // 2. Subtract 60 days from the current date
      const sixtyDaysAgo = new Date();
      sixtyDaysAgo.setDate(today.getDate() - 60);

      const depEndDate = new Date(frmGrpControl.controls.depEndDate.value);
      if (depEndDate.getTime() < sixtyDaysAgo.getTime()) {
        // Date Is past
        if (!isInsertDepCall && frmGrpControl.controls.depPKID.value !== 0) {
          this.ResetErrorForPastDeployments(frmGrpControl);
        }
        frmGrpControl['controls']['isPastdepLine'].setValue(true);
        return true;
      } else {
        frmGrpControl['controls']['isPastdepLine'].setValue(false);
      }
    }
    return false;
    // if (frmGrpControl?.controls.depPKID.value !== 0 && frmGrpControl?.controls.isSpecialPosition.value) {
    //   this.ResetErrorForPastDeployments(frmGrpControl);
    //   return true;
    // }
    // Update => when Both Dates are avaiable
    // if (frmGrpControl?.controls.depEndDate.value != null && frmGrpControl.controls.depBeginDate.value != null) {
    //   const today = new Date();
    //   const depEndDate = new Date(frmGrpControl.controls.depEndDate.value);
    //   if (depEndDate.getTime() < today.getTime()) {
    //     // If true then deployment line is past date
    //     this.ResetErrorForPastDeployments(frmGrpControl);
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    // Update => But Dates contains null
    // if (frmGrpControl.controls.depPKID.value !== 0) {
    //   this.ResetErrorForPastDeployments(frmGrpControl);
    //   if (!frmGrpControl.controls.depBeginDate.value) {
    //     frmGrpControl.controls['depBeginDate'].setErrors({ incorrect: true });
    //   }
    //   if (!frmGrpControl.controls.depEndDate.value) {
    //     frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
    //   }
    //   return true; // if its updates both dates are required
    // }
  }
  // no use of below method
  // public ValidateDeploymentLineDates() {}
  public populateServiceDetails(e?, frmGrpControls?, index?, isFunCall = false) {
    // console.log('populateServiceDetails');   
    const isPastDepLine = this.IsPastdeploymentLine(frmGrpControls);
    if (isFunCall && frmGrpControls && isPastDepLine && frmGrpControls.controls.depPKID.value !== 0) {
      // this.vesselDeploymentLines.controls[index]['controls']['depIsUpdated'].setValue(true);
      return;
    }

    // console.log(frmGrpControls);
    if (!isPastDepLine) {
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
    }
    // Updating through dep line edits
    if (index !== undefined && (!frmGrpControls.pristine || frmGrpControls.dirty)) {
      frmGrpControls.controls.depStartPortID.setValue(null);
      frmGrpControls.controls.depEndPortID.setValue(null);
      frmGrpControls.controls.depSpecialServiceStartPortID.setValue(null);
      frmGrpControls.controls.depSpecialServiceEndPortID.setValue(null);
      frmGrpControls.controls.depPosition.setValue(null);
      frmGrpControls.controls.isSpecialPosition.setValue(false);
      frmGrpControls.controls.isSpecialService.setValue(false);
    }
    // if (this.fromCreateDummy && index === 0) {
    // this.emptyDummyName.emit();
    // }
    this.showSpecialServicesFlag = false;
    this.showServicePosition = false;
    // this.showLocationHidePort = false; -> All place value is false only
    // this.showvesselDepSpclServiceTypes = false; -> All place value is false only
    //  this.showvesselDepUnemployedTypes = false;-> All place value is false only
    // this.showvesselDepLayupTypes = false;-> All place value is false only
    //  this.dontShowServiceType = false;-> All place value is false only
    // this.hideTrade = false;-> All place value is false only
    // this.hideRoundTrip = false;-> All place value is false only
    this.showScheduleDateError = false;

    if (e.isSpecial) {
      // when we edit single deplyment line below method wil call s
      // console.log('Special Service called - deployment lines ');
      frmGrpControls.controls.isSpecialService.setValue(true);
      this.portsService
        .getPorts()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (ports: any) => {
            // console.log(` populateServiceDetails ports loads `);
            // console.log(ports);
            // this.allPorts = ports;
            this.allPorts = ports.map((o) => {
              return {
                schedulePortId: o.prtPKID,
                schedulePortName: o.prtPortName,
                scheduleDate: null,
                scheduleTime: null,
                scheduleType: null,
                position: null,
                portId: o.prtPKID,
              };
            });
          },
          (errorResponse) => {
            // Error handling for end port and end date when schedule doesn;t exist.
            this.showScheduleDateError = true;
            const msg = errorResponse.error.error;
            this.scheduleDateErrorMsg = msg;
            frmGrpControls.controls['depEndPortID'].setErrors({ incorrect: true });
            frmGrpControls.controls['depEndDate'].setErrors({ incorrect: true });
          }
        );
      frmGrpControls.controls['depEndPortID'].setErrors(null);
      frmGrpControls.controls['depEndDate'].setErrors(null);
    } else {
      const dpValues = e;
      // this.servicePositions = e.positions; -> after assign this is not being used
      // looping through each deployment line
      if (Array.isArray(dpValues)) {
        const obj = { depStringID: null, depBeginDate: null };
        frmGrpControls.map((frmGrpControl, i) => {
          obj.depStringID = frmGrpControl.controls.depStringID.value;
          // Loads Ports for each service
          this.loadPortsByService(obj, frmGrpControl, i);
          this.loading = false;

          if (i === 0) {
            this.populateDummyData(dpValues);
          }
        });
      } else {
        // when we change in deployment line
        if (dpValues.serviceCode) {
          dpValues.depStringID = dpValues.strPKID;
          this.loadDataOnChangeOfServiceControl(dpValues, frmGrpControls, index);
        }
      }
    }
    if (this.fromCreateDummy && index === 0) {
      this.getServiceChangeValforDummy.emit(e);
    }
    if (!isPastDepLine) {
      this.displayErrorLines(frmGrpControls);
    }
  }
  public loadPortsByService(e?, frmGrpControl?, index?) {
    const isPastDepLine = this.IsPastdeploymentLine(frmGrpControl);
    if (isPastDepLine) {
      return;
    }
    // Refractoring required - varun joshi
    // console.log(`loadPortsByService called - index - ${index}`);
    this.showServicePosition = true;

    if (!frmGrpControl.pristine || frmGrpControl.dirty) {
      frmGrpControl.controls.depStartPortID.setValue();
      frmGrpControl.controls.depEndPortID.setValue();
    }

    if (e.depStringID) {
      const serviceId = e.depStringID;
      const beginDate = frmGrpControl.controls.depBeginDate.value
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;
      const endDate = frmGrpControl.controls.depEndDate.value
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

      // const selectedService = frmGrpControl.controls.depStringID.value; Not In USE
      const selectedDateTime = beginDate != null ? beginDate + 'T' + '00:00' : null;
      const selectedEndDateTime = endDate != null ? endDate + 'T' + '00:00' : null;
      const isSpecialPosition = frmGrpControl.controls.isSpecialPosition.value;
      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId;
      const selectedposition = frmGrpControl.controls.depPosition.value;

      if (serviceId && isSpecialPosition) {
        // console.log(`loadPortsByService = > serviceId = ${serviceId}  isSpecialPosition-${isSpecialPosition}`);
        this.loadPorts();
      } else if (serviceId && selectedDateTime) {
        // console.log(`loadPortsByService = > serviceId = ${serviceId} selectedDateTime-${selectedDateTime} `);
        this.vesselsService
          .getPortsByServiceByPosition(
            serviceId,
            e.depBeginDate ? e.depBeginDate : selectedDateTime,
            e.depEndDate ? e.depEndDate : selectedEndDateTime,
            selectedposition
          )
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(
            async (response: any) => {

              this.allPortsByService = await response; // different API // No Where we are using allPortsByService

              const positionSelected = this.ValidateExtraPositionSelection(frmGrpControl);

              const startportSet = new Set();
              const endportSet = new Set();
              if (positionSelected && positionSelected > 0) {
                this.isVesslDeplyPositionSelected = true;

                this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.position === positionSelected);

                this.depStartPortListLineWise[index] = {};

                if (frmGrpControl.controls.depStartPortID.value != null) {
                  // // console.log(JSON.stringify(this.vesselDeploymentStartPort));
                  // // console.log(JSON.stringify(frmGrpControl.controls.depStartPortID.value));
                  const objstartPortProformaPKId = this.vesselDeploymentStartPort.find(
                    (i) => i.schedulePortId === +frmGrpControl.controls.depStartPortID.value
                  );
                  // // console.log(JSON.stringify(objstartPortProformaPKId));
                  // Remove duplicate portid from array by comparing sub properties
                  // condition added to check the null record
                  if (objstartPortProformaPKId) {
                    this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                      (o) =>
                        !startportSet.has(o.schedulePortId) &&
                        startportSet.add(o.schedulePortId) &&
                        o.scheduleProformaPKId === objstartPortProformaPKId.scheduleProformaPKId
                    );
                  }
                  // // console.log(JSON.stringify(this.depStartPortListLineWise[index].ports));
                } else {
                  // Remove duplicate portid from array by comparing sub properties
                  this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                    (o) => !startportSet.has(o.schedulePortId) && startportSet.add(o.schedulePortId)
                  );
                }

                this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.position === positionSelected);
                this.depEndPortListLineWise[index] = {};

                if (frmGrpControl.controls.depEndPortID.value != null) {
                  const objendPortProformaPKId = this.vesselDeploymentStartPort.find(
                    (i) => i.schedulePortId === +frmGrpControl.controls.depEndPortID.value
                  );
                  // // console.log(JSON.stringify(this.vesselDeploymentStartPort));
                  // // console.log(JSON.stringify(frmGrpControl.controls.depEndPortID.value));
                  // // console.log(JSON.stringify(objendPortProformaPKId));

                  if (objendPortProformaPKId) {
                    // Remove duplicate portid from array by comparing sub properties
                    this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                      (o1) =>
                        !endportSet.has(o1.schedulePortId) &&
                        endportSet.add(o1.schedulePortId) &&
                        o1.scheduleProformaPKId === objendPortProformaPKId.scheduleProformaPKId
                    );
                  }
                  // // console.log(JSON.stringify(this.depEndPortListLineWise[index].ports));
                } else {
                  // Remove duplicate portid from array by comparing sub properties
                  this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                    (o1) => !endportSet.has(o1.schedulePortId) && endportSet.add(o1.schedulePortId)
                  );
                }
              } else {
                this.isVesslDeplyPositionSelected = false;
                this.vesselDeploymentStartPort = response.portDateTime;
                this.depStartPortListLineWise[index] = {};
                this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                  (o) => !startportSet.has(o.schedulePortId) && startportSet.add(o.schedulePortId)
                );

                this.vesselDeploymentEndPort = response.portDateTime;
                this.depEndPortListLineWise[index] = {};
                this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                  (o1) => !endportSet.has(o1.schedulePortId) && endportSet.add(o1.schedulePortId)
                );
              }
              // this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(
              //   this.vesselDeploymentStartPort[0].schedulePortId
              // );
              let startPortToPopulate: any;
              const startDateBeforePort = new Date(frmGrpControl.controls.depBeginDate.value);
              startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
              if (startPortToPopulate.length && (!frmGrpControl.pristine || frmGrpControl.dirty)) {
                frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
                this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;

                frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                if (this.fromCreateDummy && index === 0) {
                  this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
                }
              }
              let endPortToPopulate: any;
              const endDateBeforePort = new Date(
                new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
              );
              if (endDateBeforePort) {
                endPortToPopulate = response.portDateTime.filter(
                  (k) =>
                    positionSelected === k.position &&
                    endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                );
                if (endPortToPopulate.length && (!frmGrpControl.pristine || frmGrpControl.dirty)) {
                  frmGrpControl.controls.depEndPortID.setValue(
                    endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
                  );
                  this.schedulePrfEndPortPKId = endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;

                  frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                  frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                } else if (!endPortToPopulate.length && positionSelected && positionSelected > 0) {
                  // Error handling for end port and end date when schedule doesn;t exist.
                  if (!isPastDepLine) {
                    frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
                    frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
                  }
                }
              }
              // this.checkAdjacentLinesForSpcl(frmGrpControl, index);
              if (!isPastDepLine) {
                this.validateEndDateErrHandlng(e, frmGrpControl, index);
                this.validateStartDateErrHandlng(e, frmGrpControl, index);
                this.displayErrorLines(this.vesselDeploymentFrmGrp);
              }
            },
            (errorResponse) => {
              // Error handling for end port and end date when schedule doesn;t exist.
              this.HandleSchedulesNotExistForPastDates(errorResponse, index, frmGrpControl);
              // this.showScheduleDateError = true;
              // const msg = errorResponse.error ? errorResponse.error.error : errorResponse.error;
              // this.scheduleDateErrorMsg = msg;
              // // this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({'incorrect': true});
              // this.depEndPortListLineWise[index] = {};
              // this.depEndPortListLineWise[index].ports = [];
              // this.depStartPortListLineWise[index] = {};
              // this.depStartPortListLineWise[index].ports = [];
              // frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
              // frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
              // this.displayErrorLines(this.vesselDeploymentFrmGrp);
              // this.spinner.hide();
            }
          );
        frmGrpControl.controls['depEndPortID'].setErrors(null);
        frmGrpControl.controls['depEndDate'].setErrors(null);
        // Validate Logic
        if (!isPastDepLine) {
          this.validateEndDateErrHandlng(e, frmGrpControl, index);
          this.validateStartDateErrHandlng(e, frmGrpControl, index);
        }
      }
      // TODO : No Need of this below Method while loading screen but when we edit  if we dont call there is an error coding we need to re
      // write this logic
      // this.loadVesselsDeploymentServicseAndResons(e.depStringID, e, frmGrpControl, index);
    }
  }
  public loadPorts(frmGrpControl?, index?) {
    const isPastDepLine = this.IsPastdeploymentLine(frmGrpControl);

    if (this.allPorts) {
      // console.log(`loadPorts Exist =>${this.allPorts.length}`);
      this.ValidateLoadPorts(frmGrpControl, index, isPastDepLine);
    } else {
      this.GetPortsOnLoad(true, frmGrpControl, index);
    }
    if (frmGrpControl && !isPastDepLine) {
      frmGrpControl.controls['depEndPortID'].setErrors(null);
      frmGrpControl.controls['depEndDate'].setErrors(null);
    }
  }

  public GetPortsOnLoad(loadPortCallBack: boolean, frmGrpControl?: any, index?: any) {
    // console.log(`GetPortsOnLoad`);
    this.portsService
      .getPorts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (ports: any) => {
          // console.log(`API loadPorts => `);
          this.allPorts = await ports;
          this.allPorts = ports.map((o) => {
            return {
              schedulePortId: o.prtPKID,
              schedulePortName: o.prtPortName,
              scheduleDate: null,
              scheduleTime: null,
              scheduleType: null,
              position: null,
              portId: o.prtPKID,
            };
          });
          if (loadPortCallBack) {
            this.loadPorts(frmGrpControl, index);
          } else {
            this.InitialLoadData();
          }
        },
        (errorResponse) => {
          // Error handling for end port and end date when schedule doesn;t exist.
          this.showScheduleDateError = true;
          const msg = errorResponse.error.error;
          this.scheduleDateErrorMsg = msg;
          frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
          frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
          this.spinner.hide();
        }
      );
  }

  // Load Services and reasons
  public loadServiceAndDplyReasonList() {
    this.vesselsService
      .getVesselDeploymentDropdownsServicesList(this.vslId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vesselDeploymentDropdowns: Response) => {
        if (vesselDeploymentDropdowns) {
          //  this.vesselDeploymentDropdownsOnInit = vesselDeploymentDropdowns;
          //  this.deploymentDropdown = this.vesselDeploymentDropdownsOnInit;
          this.deploymentDropdown = vesselDeploymentDropdowns;
          this.vesselDeploymentServices = this.deploymentDropdown.servicesList.map((o) => {
            return {
              routeCode: o.routeCode,
              serviceCode: o.serviceCode,
              strPKID: o.serviceId,
              strNameCode: o.serviceName,
              svcName: o.svcName,
              isSpecial: o.isSpecial,
              lstProformas: o.lstProformas,
            };
          });
          this.vesselDeploymentReasons = this.deploymentDropdown.deploymentReasons.map((o) => {
            return {
              drDescription: o.drDescription,
              drIsActive: o.drIsActive,
              drReason: o.drReason,
              drpkID: o.drpkID,
            };
          });
          this.vesselDeploymentSpclServices = this.deploymentDropdown.specialPositionsList;
        }
      });
  }
  public populateDummyData(dpValues) {
    setTimeout(() => {
      const serviceObj = this.vesselDeploymentServices.filter((serv) => serv.strPKID === +dpValues.depStringID);
      dpValues.lstProformas = serviceObj.lstProfomas;
      this.getServiceChangeValforDummy.emit(dpValues);
    }, 1000);

    // this.getPositionChangeValforDummy.emit(dpValues.);
  }
  // public loadVesselsDeploymentServicseAndResons(serviceId?, e?, frmGrpControl?, index?) {
  //   // console.log(`loadVesselsDeploymentServicseAndResons - ${serviceId}`);
  //   const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId;
  //   if (serviceId) {
  //     this.vesselsService
  //       .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((vesselDeploymentDropdowns: Response) => {
  //         this.vesselDeploymentDropdowns = vesselDeploymentDropdowns;
  //         const vesselDeploymentServices = this.vesselDeploymentDropdowns.services.map((o) => {
  //           return {
  //             strPKID: o.strPKID,
  //             strCode: o.strCode,
  //             strName: o.strName,
  //             strRoundTrip: o.strRoundTrip,
  //             strTrade: o.strTrade,
  //             isSpecial: o.isSpecial,
  //             strNameCode: o.strCode + '  ' + o.strName,
  //             positions: o.positions,
  //             positionMaxDateByProforma: o.positionMaxDateByProforma,
  //           };
  //         });
  //         // below commneted not being used
  //         this.servicePositionsDetailsLst = vesselDeploymentServices;
  //         // console.log(` this.servicePositionsDetailsLst `);
  //         // console.log(this.servicePositionsDetailsLst);
  //         // this.vesselDeploymentSpclServices = vesselDeploymentServices.filter((o) => o.isSpecial === true);
  //         // this.vesselDepSpclServiceTypes = this.vesselDeploymentDropdowns.specialSerivceTypes;
  //         // this.vesselDepUnemployedTypes = this.vesselDeploymentDropdowns.unemployedeTypes;
  //         // this.vesselDepLayupTypes = this.vesselDeploymentDropdowns.layupTypes;
  //         // this.vesselDeploymentPorts = this.vesselDeploymentDropdowns.ports;
  //         // this.fixturePortsForRange = this.vesselDeploymentDropdowns.fixturePorts;
  //         this.vesselDeploymentReasons = this.vesselDeploymentDropdowns.deploymentReasons;
  //         this.previousDeploymentId = this.vesselDeploymentDropdowns.previousDeploymentId;
  //       });
  //   }
  // }
  public loadDataOnChangeOfServiceControl(serviceObject?, frmGrpControl?, index?) {
    const serviceId = serviceObject.depStringID ? serviceObject.depStringID : serviceObject;
    this.loadPortsByService(serviceObject, frmGrpControl, index);
    return;
  }
  public showSpecialServices() {
    this.showSpecialServicesFlag = true;
  }

  // TODO : Need Inputs To solve this
  public controlsValueChanges(frmGrp?, isFunCall = false) {
    // console.log('controlsValueChanges');
    // if (isFunCall && frmGrp && this.IsPastdeploymentLine(frmGrp)) {
    //   this.vesselDeploymentLines.controls.forEach((control, index) => {
    //     if (this.vesselDeploymentLines.controls[index].dirty) {
    //       // this.vesselDeploymentLines.controls[index]['controls']['depIsUpdated'].setValue(true);
    //       this.isCancelShowPreviousClick = false;
    //     }
    //   });
    //   return;
    // }
    // const lineInGap = false; => No Where Used
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    this.vesselDeploymentLines.controls.forEach((control, index) => {
      control.valueChanges.pipe(debounceTime(1), distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe(() => {
        // Validation position pattern
        control.get('depPosition').valueChanges.subscribe((value) => {
          const patternMatched = this.validatePositionPattern(value);
          if (!patternMatched) {
            this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });
          }
        });
        // Deployment line Validation
        let isdeplineEmpty = false;
        let isSpecialPosition = false;
        if (!this.isOwnDeploymentType) {
          if (this.vesselDeploymentLines.value.length >= 1) {
            if (this.vesselDeploymentLines.value[index] && !this.vesselDeploymentLines.value[index].isSpecialPosition) {
              // save after delete button
              this.isDepLinesValid = [];
              this.vesselDeploymentLines.value.forEach((value, i) => {
                if (this.vesselDeploymentLines.value.length - 2 > i) {
                  isdeplineEmpty = this.setSaveAfterDeleteState(i);
                }
              });
            } else {
              isSpecialPosition = true;
              // if Any Special Service
              if (
                this.vesselDeploymentLines.value[index] &&
                (this.vesselDeploymentLines.value[index].depSpecialServiceStartPortID === null ||
                  this.vesselDeploymentLines.value[index].depSpecialServiceEndPortID === null)
              ) {
                isdeplineEmpty = true;
                this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
                this.vesselDeploymentFrmGrp.updateValueAndValidity();
              }
            }
          }
        } else {
          if (this.vesselDeploymentLines.value.length >= 1 && this.vesselDeploymentLines.value[index]) {
            if (!this.vesselDeploymentLines.value[index].isSpecialPosition) {
              if (
                this.vesselDeploymentLines.value[index].depBeginDate === null ||
                this.vesselDeploymentLines.value[index].depEndDate === null ||
                this.vesselDeploymentLines.value[index].depStringID === null ||
                this.vesselDeploymentLines.value[index].depPosition === null ||
                this.vesselDeploymentLines.value[index].depReasonCodeID === null ||
                this.vesselDeploymentLines.value[index].depStartPortID === null ||
                this.vesselDeploymentLines.value[index].depEndPortID === null
              ) {
                isdeplineEmpty = true;
                this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
                this.vesselDeploymentFrmGrp.updateValueAndValidity();
              } else {
                if (
                  this.vesselDeploymentLines.value[index].depBeginDate !== null &&
                  this.vesselDeploymentLines.value[index].depEndDate !== null &&
                  this.vesselDeploymentLines.value[index].depStringID !== null &&
                  this.vesselDeploymentLines.value[index].depPosition !== null &&
                  this.vesselDeploymentLines.value[index].depReasonCodeID !== null &&
                  this.vesselDeploymentLines.value[index].depStartPortID !== null &&
                  this.vesselDeploymentLines.value[index].depEndPortID !== null
                ) {
                  isdeplineEmpty = false;
                  this.vesselDeploymentFrmGrp.setErrors(null);
                  this.vesselDeploymentFrmGrp.updateValueAndValidity();
                }
              }
            } else {
              isSpecialPosition = true;
              // if Any Special Service
              if (
                this.vesselDeploymentLines.value[index].depSpecialServiceStartPortID === null ||
                this.vesselDeploymentLines.value[index].depSpecialServiceEndPortID === null
              ) {
                isdeplineEmpty = true;
                this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
              }
            }
          }
        }
        if (!isdeplineEmpty) {
          this.vesselDeploymentFrmGrp.setErrors(null);
          this.vesselDeploymentFrmGrp.updateValueAndValidity();
        }

        this.isAnyControlModified = true;
        // this.showWarningOverlapping = false;
        // this.showErrorOverlapping = false;
        // this.warnings = [];
        // this.showPortRangeError = false;
        // this.showIncorrectDetailError = false;
        if ((this.fromVesselListPage && this.fromVesselListVslId) || this.fromCreateDummy) {
          const emitObj = {
            isInvalid: this.vesselDeploymentLines.invalid,
            isModified: this.isAnyControlModified,
            isPristine: this.vesselDeploymentLines.pristine,
            isDirty: this.vesselDeploymentLines.dirty,
            controlIndex: index,
            isdeplineEmpty: isdeplineEmpty,
            isSpecialPosition: isSpecialPosition,
          };
          this.depControlValueChangeEventsEmiter.emit(emitObj);
        }
      });

      if (this.vesselDeploymentLines.controls[index].dirty) {
        this.vesselDeploymentLines.controls[index]['controls']['depIsUpdated'].setValue(true);
        this.isCancelShowPreviousClick = false;
      }
    });
  }
  public setSaveAfterDeleteState(index) {
    if (
      this.vesselDeploymentLines.value[index].depBeginDate === null ||
      this.vesselDeploymentLines.value[index].depEndDate === null ||
      this.vesselDeploymentLines.value[index].depStringID === null ||
      this.vesselDeploymentLines.value[index].depPosition === null ||
      this.vesselDeploymentLines.value[index].depReasonCodeID === null ||
      this.vesselDeploymentLines.value[index].depStartPortID === null ||
      this.vesselDeploymentLines.value[index].depEndPortID === null
    ) {
      this.isDepLinesValid.push('true');
      this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
      this.vesselDeploymentFrmGrp.updateValueAndValidity();
    } else {
      if (
        this.vesselDeploymentLines.value[index].depBeginDate !== null &&
        this.vesselDeploymentLines.value[index].depEndDate !== null &&
        this.vesselDeploymentLines.value[index].depStringID !== null &&
        this.vesselDeploymentLines.value[index].depPosition !== null &&
        this.vesselDeploymentLines.value[index].depReasonCodeID !== null &&
        this.vesselDeploymentLines.value[index].depStartPortID !== null &&
        this.vesselDeploymentLines.value[index].depEndPortID !== null
      ) {
        this.isDepLinesValid.push('false');
        this.vesselDeploymentFrmGrp.setErrors(null);
        this.vesselDeploymentFrmGrp.updateValueAndValidity();
      }
    }
    const isdeplineEmpty = this.isDepLinesValid.some((val) => val === 'true');
    isdeplineEmpty
      ? this.vesselDeploymentFrmGrp.setErrors({ invalid: true })
      : this.vesselDeploymentFrmGrp.setErrors(null);
    return isdeplineEmpty;
  }
  // Start Port Change Event
  public populateStartDate(e, frmGrp, index, isFunCall = false) {
    // console.log('populateStartDate');
    this.controlsValueChanges();
    if (this.fromCreateDummy && index === 0) {
      this.getStartPortChangeValforDummy.emit(e);
    }

    if (isFunCall && this.IsPastdeploymentLine(frmGrp) && frmGrp.controls.depPKID.value !== 0) {
      frmGrp.controls.depSpecialServiceStartPortID.setValue(e.schedulePortId);
      if (!frmGrp.controls.depSpecialServiceEndPortID.value) {
        frmGrp.controls.depSpecialServiceEndPortID.setValue(frmGrp.controls.depPastEndPortId?.value);
      }
      frmGrp.controls.isSpecialPosition.setValue(true);
      frmGrp.controls.depStartPortID.setValue(null);
      return;
    }

    if (frmGrp.controls.isSpecialPosition.value) {
      frmGrp.controls.depSpecialServiceStartPortID.setValue(e.schedulePortId);
      frmGrp.controls.depStartPortID.setValue(null);
    }
    if (index > 0) {
      const previousIndexDepLine = this.getValue(index - 1);
      if (+previousIndexDepLine.value.isSpecialPosition) {
        const portsFromThisDepLine = frmGrp.controls.isSpecialPosition.value
          ? this.allPorts
          : this.depStartPortListLineWise[index].ports;

        const portIdInThisLine =
          frmGrp.controls.depStartPortID.value || frmGrp.controls.depSpecialServiceStartPortID.value;

        const portUsedInThisLine = portsFromThisDepLine.filter((p) => p.schedulePortId === +portIdInThisLine);

        const filterPortForPrevIndex = this.allPorts.filter((ap) => ap.schedulePortId === portUsedInThisLine[0].portId);

        previousIndexDepLine['controls'].depSpecialServiceEndPortID.setValue(filterPortForPrevIndex[0].schedulePortId);
      }
    }
    this.displayErrorLines(frmGrp);
  }
  // End Port Change Event
  public populateEndDate(e, frmGrp, index, isFunCall = false) {
    // console.log('populateEndDate');
    if (isFunCall && this.IsPastdeploymentLine(frmGrp) && frmGrp.controls.depPKID.value !== 0) {
      frmGrp.controls.depSpecialServiceEndPortID.setValue(e.schedulePortId);

      if (!frmGrp.controls.depSpecialServiceStartPortID.value) {
        frmGrp.controls.depSpecialServiceStartPortID.setValue(frmGrp.controls.depPastStartPortId?.value);
      }
      frmGrp.controls.isSpecialPosition.setValue(true);
      frmGrp.controls.depEndPortID.setValue(null);
      return;
    }
    this.controlsValueChanges();
    if (frmGrp.controls.isSpecialPosition.value) {
      frmGrp.controls.depSpecialServiceEndPortID.setValue(e.schedulePortId);
      frmGrp.controls.depEndPortID.setValue(null);
    }
    if (index >= 0) {
      const nextIndexDepLine = this.getValue(index + 1);
      if (nextIndexDepLine && nextIndexDepLine.value.isSpecialPosition) {
        const portsFromThisDepLine = frmGrp.controls.isSpecialPosition.value
          ? this.allPorts
          : this.depEndPortListLineWise[index].ports;

        const portIdInThisLine = frmGrp.controls.depEndPortID.value || frmGrp.controls.depSpecialServiceEndPortID.value;

        const portUsedInThisLine = portsFromThisDepLine.filter((p) => p.schedulePortId === +portIdInThisLine);

        const filterPortForPrevIndex = this.allPorts.filter((ap) => ap.schedulePortId === portUsedInThisLine[0].portId);

        nextIndexDepLine['controls'].depSpecialServiceStartPortID.setValue(filterPortForPrevIndex[0].schedulePortId);
      }
    }
    this.displayErrorLines(frmGrp);
  }
  // Add new Deployment line
  public insertDeployment(index) {
    this.clearAlertService();
    const newDeploymentLine = {
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: null,
      depPosition: null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: this.vesselDeploymentLines.value[index].depEndDate,
      depEndDate: null,
      depReasonCodeID: null,
      depComments: '',
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,
      durationFixtureEndDate: null,
      vesselOwnType: this.fromCreateDummy ? 'Dummy' : this.vesselDetail.vslVesselType,
      depVesselCodeId: this.fromCreateDummy ? 0 : this.veselID,
      setDepEndDateErrorCSS: false,
      isMultipleProformas: false,
      depProformaID: null,
      isPastdepLine: false,
      depPastStartPortId: null,
      depPastEndPortId: null,
      depEmailStatus: 'Not Required',
      depEmailReason: 'No Reason'
    };
    this.depStartPortListLineWise.splice(index + 1, 0, { ports: [] });
    this.depEndPortListLineWise.splice(index + 1, 0, { ports: [] });
    this.vesselDeploymentLines.insert(index + 1, this.fb.group(newDeploymentLine));
    setTimeout(() => {
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
    }, 1);
  }

  // delete Deployment line
  public deleteDeployment(index?, control?) {
    const dialogRef = this.dialog.open(this.deleteDeploymentDialogue, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (control.value.depPKID && (control.value.depVesselID || this.fromCreateDummy)) {
            control.value.depIsDeleted = true;
            control.value.depIsUpdated = false;
            this.deletedDeploymentLines.push(control.value);
            control.patchValue(control.value.depIsDeleted, { emitEvent: false });
            this.vesselDeploymentLines.updateValueAndValidity();
            this.isAnyControlModified = true;
          }
          this.removeDeploymentLines(index);
          // this.CheckDuplicateDepLineWithPrevDepLines();
          this.displayErrorLines(this.vesselDeploymentFrmGrp);
          this.isCancelShowPreviousClick = false;
          if (!this.isOwnDeploymentType) {
            this.isDepLinesValid = [];
            this.vesselDeploymentLines.value.forEach((value, i) => {
              if (this.vesselDeploymentLines.value.length - 2 > i) {
                this.setSaveAfterDeleteState(i);
              }
            });
          }
        }
      });
  }

  public removeDeploymentLines(index): void {
    this.clearAlertService();
    if (index > 0 && index < this.vesselDeploymentLines.length - 1) {
      const prevIndexDepLine = this.getValue(index - 1);
      const nextIndexDepLine = this.getValue(index + 1);
      if (nextIndexDepLine && prevIndexDepLine) {
        // Bug 331371 Save button is not getting enabled after deleting deployment line
        const BeginDate = nextIndexDepLine.value.depBeginDate !== null && nextIndexDepLine.value.depBeginDate;
        const EndDate = nextIndexDepLine.value.depEndDate !== null && nextIndexDepLine.value.depEndDate;
        if (BeginDate && !EndDate) {
          nextIndexDepLine['controls'].depBeginDate.setValue(prevIndexDepLine.value.depEndDate);
        }
      }
    }

    if (index !== 0) {
      this.vesselDeploymentLines.removeAt(index);
      // this.vesselDeploymentFrmGrp.markAsDirty();
      this.vesselDeploymentFrmGrp.setErrors(null);
    } else if (!this.fromCreateDummy && index === 0) {
      this.vesselDeploymentLines.removeAt(index);
      this.vesselDeploymentFrmGrp.markAsDirty();
    }
    // this.depStartPortListLineWise.splice(index, 1);
    this.depStartPortListLineWise.splice(index, 1);
    this.depEndPortListLineWise.splice(index, 1);
    const lastYearVesselPrevDepObj = this.lastYearPreviousDeployments();
    let isPrevDepAvailable = true;
    const deletedDeploymentLines = this.deletedDeploymentLines.filter((obj, indx, self) => {
      return (
        indx ===
        self.findIndex((t) => {
          return t.depPKID === obj.depPKID;
        })
      );
    });
    if (this.totalDepLines === deletedDeploymentLines.length) {
      isPrevDepAvailable = false;
    }

    // if (this.deletedDeploymentLines[0].depIsDeleted) {
    //   isPrevDepAvailable = this.IsPrevDepAvaiable(this.deletedDeploymentLines[0].beginDate);
    // }

    // const isPrevDepAvailable =
    //   (!this.isPreviousDeployments && lastYearVesselPrevDepObj.length > 0) ||
    //   (this.isPreviousDeployments && this.islastYearOptionChecked && lastYearVesselPrevDepObj.length > 0) ||
    //   (this.isPreviousDeployments && !this.islastYearOptionChecked && this.vesselDeploymentLines.length > 0);

    // const isPrevDepAvailable =
    //   this.lastYearVesselPreviousDeploymentsObj != null &&
    //   this.lastYearVesselPreviousDeploymentsObj.length > 1 &&
    //   !this.islastYearOptionChecked;

    if (this.fromCreateDummy && index === 0) {
      const emittedObj = this.emitDummyObjectOnlyForFirstDeploymentLine();
      if (emittedObj) {
        this.getServiceChangeValforDummy.emit(emittedObj);
      }
      if (
        this.deletedDeploymentLines[0].depIsDeleted &&
        this.vesselDeploymentLines.value[1].depEndDate == null &&
        this.vesselDeploymentLines.value[1].depPosition == null &&
        !isPrevDepAvailable
      ) {
        this.vesselDeploymentLines.removeAt(index);
        const currentIndexDepLine = this.getValue(index);
        currentIndexDepLine['controls'].depBeginDate.setValue(null);
        // this.vesselDeploymentFrmGrp.markAsDirty();
        // Delete dummy
        const emitObjDelete = {
          isModified: this.isAnyControlModified,
          isDummyDeleted: true,
        };
        if (this.fromCreateDummy) {
          this.depControlValueChangeEventsEmiter.emit(emitObjDelete);
        }
      } else {
        this.vesselDeploymentLines.removeAt(index);
        // this.vesselDeploymentFrmGrp.markAsDirty();
      }

      // •	In case of 2 deployment lines, deleting the first deployment should change vessel name
      if (this.vesselDeploymentLines.value.length > 1 && index === 0) {
        const emitObj1 = this.emitUpdatedNameForDummy();
        if (emitObj1) {
          this.getUpdatedNameForDummy.emit(emitObj1);
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    const emitObj = {
      isInvalid: this.vesselDeploymentLines.invalid,
      isModified: this.isAnyControlModified,
      isPristine: false,
      isDirty: this.vesselDeploymentLines.dirty,
      controlIndex: index,
    };
    if (this.fromCreateDummy) {
      this.depControlValueChangeEventsEmiter.emit(emitObj);
    }
  }
  public emitDummyObjectOnlyForFirstDeploymentLine() {
    const stringObj = this.vesselDeploymentServices.filter(
      (s) => s.strPKID === this.vesselDeploymentLines.value[0].depStringID
    );
    const position = this.vesselDeploymentLines.value[0].depPosition;
    const startPort = this.depStartPortListLineWise[0]
      ? this.depStartPortListLineWise[0].ports.filter(
          (p) => p.schedulePortId === this.vesselDeploymentLines.value[0].depStartPortID
        )
      : null;
    if (stringObj.length > 0) {
      const dataTobeEmitted = {
        stringObj: stringObj[0].serviceCode,
        position: position,
        startPort: startPort && startPort.length ? startPort[0].schedulePortName : null,
      };
    }
    // return (this.vesselDeploymentLines.value)[0].isSpecialPosition ? null : dataTobeEmitted;
    return stringObj;
  }
  public emitUpdatedNameForDummy() {
    const stringObj = this.vesselDeploymentServices.filter(
      (s) => s.strPKID === this.vesselDeploymentLines.value[0].depStringID
    );
    const position = this.vesselDeploymentLines.value[0].depPosition;
    const startPort = this.depStartPortListLineWise[0]
      ? this.depStartPortListLineWise[0].ports.filter(
          (p) => p.schedulePortId === this.vesselDeploymentLines.value[0].depStartPortID
        )
      : null;
    if (stringObj.length > 0) {
      const dataTobeEmitted = {
        stringObj: stringObj[0].serviceCode,
        position: position,
        startPort: startPort && startPort.length ? startPort[0].schedulePortName : null,
      };
      return this.vesselDeploymentLines.value[0].isSpecialPosition ? null : dataTobeEmitted;
    }
    return stringObj;
  }

  // Validation Methods for deployment lines
  public validateStartDateErrHandlng(e?, frmGrpControls?, index?) {
    // Error Handling - If start date not match in any proforma-Validation
    let isInValid = false;
    if (
      frmGrpControls &&
      frmGrpControls.controls.depStringID.value &&
      frmGrpControls.controls.depPosition.value != null
    ) {
      let sortedPrfLst;
      let seldateInvalid = false;

      const selectedProformaID = this.checkedProformaDtl ? this.checkedProformaDtl.proformaID : null;

      if (this.matchedProformaLst.length > 1) {
        this.posPrfLst.map((k) => {
          if (k.proformaID === selectedProformaID) {
            sortedPrfLst = new Date(k.minDate);
            seldateInvalid = false;
            this.isMultipleProformasflag = false;
          }
        });
      } else if (this.matchedProformaLst.length === 1) {
        this.matchedProformaLst.forEach((o) => {
          this.posPrfLst.map((k) => {
            if (k.proformaID === o.proformaID) {
              sortedPrfLst = new Date(k.minDate);
              seldateInvalid = false;
              this.isMultipleProformasflag = false;
            }
          });
        });
      } else {
        if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
          sortedPrfLst = new Date(Math.min(...this.posPrfStartEndDtLst.map((m) => new Date(m.minDate))));
          seldateInvalid = true;
          this.isMultipleProformasflag = true;
        } else {
          seldateInvalid = false;
        }
      }

      const selStartDate = new Date(
        new DatePipe('en-US').transform(frmGrpControls.controls.depBeginDate.value, 'dd-MMM-yyyy')
      );

      if (!frmGrpControls.controls.isSpecialService.value) {
        if (this.posPrfLst.length > 0 || seldateInvalid) {
          const prfStartDate = new Date(new DatePipe('en-US').transform(sortedPrfLst, 'dd-MMM-yyyy'));

          if (selStartDate.getTime() < prfStartDate.getTime()) {
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
            this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setValue(null);
            this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setErrors({ incorrect: true });
            this.spinner.hide();
            isInValid = true;
          } else {
            if (this.vesselDeploymentLines.controls[index]) {
              this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
              this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setErrors(null);
            }
          }
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    return isInValid;
  }
  public validateEndDateErrHandlng(e?, frmGrpControls?, index?) {
    let isInValid = false;
    if (
      frmGrpControls &&
      frmGrpControls.controls.depStringID.value &&
      frmGrpControls.controls.depPosition.value != null &&
      !this.isCancelShowPreviousClick
    ) {
      let sortedPrfLst;
      let seldateInvalid = false;
      // console.log(` this.checkedProformaDtl`);
      // console.log(this.checkedProformaDtl);
      const selectedProformaID = this.checkedProformaDtl ? this.checkedProformaDtl.proformaID : null;

      if (this.matchedProformaLst.length > 1) {
        this.posPrfLst.map((k) => {
          if (k.proformaID === selectedProformaID) {
            sortedPrfLst = new Date(k.maxDate);
            seldateInvalid = false;
            this.isMultipleProformasflag = false;
          }
        });
      } else if (this.matchedProformaLst.length === 1) {
        this.matchedProformaLst.forEach((o) => {
          this.posPrfLst.map((k) => {
            if (k.proformaID === o.proformaID) {
              sortedPrfLst = new Date(k.maxDate);
              seldateInvalid = false;
              this.isMultipleProformasflag = false;
            }
          });
        });
      } else {
        if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
          sortedPrfLst = new Date(Math.max(...this.posPrfStartEndDtLst.map((m) => new Date(m.maxDate))));
          seldateInvalid = true;
          this.isMultipleProformasflag = true;
        } else {
          this.posPrfLst.map((k) => {
            sortedPrfLst = new Date(k.maxDate);
          });
          seldateInvalid = false;
        }
      }

      const selEndDate = new Date(
        new DatePipe('en-US').transform(frmGrpControls.controls.depEndDate.value, 'dd-MMM-yyyy')
      );

      if (!frmGrpControls.controls.isSpecialService.value) {
        const prfEndDate = new Date(new DatePipe('en-US').transform(sortedPrfLst, 'dd-MMM-yyyy'));
        if (this.posPrfLst.length > 0 || seldateInvalid) {
          if (selEndDate.getTime() > prfEndDate.getTime()) {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setValue(null);
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setErrors({ incorrect: true });
            this.spinner.hide();
            isInValid = true;
          } else {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setErrors(null);
          }
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    return isInValid;
  }

  public CheckDuplicateDepLineWithPrevDepLines() {
    if (!this.vesselPreviousDeployments || this.vesselPreviousDeployments.length === 0) {
      return;
    }
    let prevDeplymentLines = [...this.vesselPreviousDeployments];
    if (this.deletedDeploymentLines.length > 0) {
      // Removed the Dep line if it deleted
      const deletedidList: number[] = this.deletedDeploymentLines.map((obj) => obj.depPKID);
      prevDeplymentLines = this.vesselPreviousDeployments.filter((item) => !deletedidList.includes(item.depPKID));
    }
    this.errorInPrevDepLinesMessage = null;
    this.errorInPrevDepLines = false;
    const previdList: number[] = prevDeplymentLines.map((obj) => obj.depPKID);
    const currentidList: number[] = this.vesselDeploymentLines.value.map((obj) => obj.depPKID);
    // Step 1  Validate Duplicate Deployment line with current and previous
    this.vesselDeploymentLines.value.forEach((item, index) => {
      // Validate Dep line with prev avaible dep lines
      const startDate =
        typeof item.depBeginDate === 'string' ? moment(item.depBeginDate, 'YYYY-MM-DD') : item.depBeginDate;
      const endDate = typeof item.depEndDate === 'string' ? moment(item.depEndDate, 'YYYY-MM-DD') : item.depEndDate;

      const foundObject = prevDeplymentLines.find(
        (obj) =>
          moment(obj.depBeginDate, 'YYYY-MM-DD').isSame(startDate, 'day') &&
          moment(obj.depEndDate, 'YYYY-MM-DD').isSame(endDate, 'day') &&
          obj.depStringID === item.depStringID &&
          obj.depPKID !== item.depPKID
      );
      if (foundObject) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depStringID'].setErrors({ incorrect: true });
        this.errorInPrevDepLines = true;
        if (!this.errorInPrevDepLinesMessage) {
          this.errorInPrevDepLinesMessage = 'Duplicate deployment line found';
        }
      }
    });
    // Step 2 Check All Deployment line are in order
    const prevDepLine = prevDeplymentLines.filter((item) => !currentidList.includes(item.depPKID));
    const mergedDepLineList = [...prevDepLine, ...this.vesselDeploymentLines.value];
    let isInValid = false;
    for (let i = 0; i < mergedDepLineList.length; i++) {
      if (!mergedDepLineList[i].depStringID) {
        continue;
      }

      // const currentEndDate = mergedDepLineList[i]
      // ? moment(mergedDepLineList[i].depEndDate, 'YYYY-MM-DD')
      //  : null;

      // const nextStartDate = mergedDepLineList[i + 1]
      //   ? moment(mergedDepLineList[i + 1].depBeginDate, 'YYYY-MM-DD')
      //   : null;

      const currentEndDate =
        mergedDepLineList[i] && typeof mergedDepLineList[i].depEndDate === 'string'
          ? moment(mergedDepLineList[i].depEndDate, 'YYYY-MM-DD')
          : mergedDepLineList[i].depEndDate;
      const nextStartDate =
        mergedDepLineList[i + 1] && typeof mergedDepLineList[i + 1].depBeginDate === 'string'
          ? moment(mergedDepLineList[i + 1].depBeginDate, 'YYYY-MM-DD')
          : mergedDepLineList[i + 1].depBeginDate;

      if (nextStartDate && currentEndDate && nextStartDate.format('YYYY-MM-DD') < currentEndDate.format('YYYY-MM-DD')) {
        isInValid = true;
        this.vesselDeploymentLines.value.forEach((item, index) => {
          if (
            item.depPKID === mergedDepLineList[i].depPKID &&
            item.depStringID === mergedDepLineList[i].depStringID &&
            item.depEndDate !== null
          ) {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
          }
          if (
            item.depPKID === mergedDepLineList[i + 1].depPKID &&
            item.depStringID === mergedDepLineList[i + 1].depStringID &&
            item.depEndDate !== null
          ) {
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
          }
        });
      }
    }
    if (isInValid) {
      this.errorInPrevDepLines = true;
      const message = 'End date and next start dates sequence error';
      this.errorInPrevDepLinesMessage =
        this.errorInPrevDepLinesMessage === null ? message : `${this.errorInPrevDepLinesMessage},${message}`;
    }
    // this.vesselDeploymentLines.updateValueAndValidity()
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
    this.ValidatedeploymentForm();
  }
  public checkMinStartDateAndEquality(checkWithPrevDate = false) {
    const depLines = this.vesselDeploymentLines.value;
    for (const [index, item] of depLines.entries()) {
      const beginDate = item.depBeginDate
        ? typeof item.depBeginDate === 'string'
          ? moment(item.depBeginDate, 'YYYY-MM-DD')
          : item.depBeginDate
        : null;

      const endDate = item.depEndDate
        ? typeof item.depEndDate === 'string'
          ? moment(item.depEndDate, 'YYYY-MM-DD')
          : item.depEndDate
        : null;

      // Check For Minimum Date Requirement
      if (
        beginDate &&
        endDate &&
        (beginDate.format('YYYY-MM-DD') < this.depErrorLineMinDate ||
          beginDate.format('YYYY-MM-DD') > endDate.format('YYYY-MM-DD'))
      ) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      }

      if (endDate && beginDate && endDate.format('YYYY-MM-DD') < beginDate.format('YYYY-MM-DD')) {
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
      // Both Begin & End Shold Not Equal
      if (endDate && beginDate && beginDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
      if (checkWithPrevDate && index > 0) {
        // const prevEndDate = moment(depLines[index - 1].depEndDate);
        // const formattedPrevEndDate = prevEndDate.isValid() ? prevEndDate.format('YYYY-MM-DD') : null;

        const prevEndDate =
          typeof depLines[index - 1].depEndDate === 'string'
            ? moment(depLines[index - 1].depEndDate, 'YYYY-MM-DD')
            : depLines[index - 1].depEndDate;

        if (
          index > 0 &&
          beginDate &&
          prevEndDate &&
          prevEndDate.format('YYYY-MM-DD') > beginDate.format('YYYY-MM-DD')
        ) {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
          this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
        } else {
          this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
        }
      }
      if (this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].valid) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
      }
    }
    this.vesselDeploymentLines.updateValueAndValidity();
  }
  public checkMinStartDateAndEqualityForm(frmGrpControl, index, checkWithPrevDate = false) {
    if (!index) {
      return;
    }
    const beginDate = frmGrpControl?.controls.depBeginDate.value
      ? typeof frmGrpControl?.controls.depBeginDate.value === 'string'
        ? moment(frmGrpControl.controls.depBeginDate.value, 'YYYY-MM-DD')
        : frmGrpControl?.controls.depBeginDate.value
      : null;

    const endDate = frmGrpControl?.controls.depEndDate.value
      ? typeof frmGrpControl?.controls.depEndDate.value === 'string'
        ? moment(frmGrpControl.controls.depEndDate.value, 'YYYY-MM-DD')
        : frmGrpControl?.controls.depEndDate.value
      : null;

    // Check For Minimum Date Requirement
    if (beginDate && endDate && beginDate.format('YYYY-MM-DD') < this.depErrorLineMinDate) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      this.vesselDeploymentLines.updateValueAndValidity();
    }

    const depLines = this.vesselDeploymentLines.value;
    // Check For Start and End Date
    if (beginDate && endDate && beginDate.format('YYYY-MM-DD') > endDate.format('YYYY-MM-DD')) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
    }

    if (endDate && beginDate && endDate.format('YYYY-MM-DD') < beginDate.format('YYYY-MM-DD')) {
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
    }
    // Both Begin & End Shold Not Equal
    if (endDate && beginDate && beginDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
    }

    if (checkWithPrevDate && index > 0) {
      // const prevEndDate = moment(depLines[index - 1].depEndDate);
      // const formattedPrevEndDate = prevEndDate.isValid() ? prevEndDate.format('YYYY-MM-DD') : null;

      const prevEndDate =
        typeof depLines[index - 1].depEndDate === 'string'
          ? moment(depLines[index - 1].depEndDate, 'YYYY-MM-DD')
          : depLines[index - 1].depEndDate;

      // console.log(`${beginDate} -- ${prevEndDate}`);
      if (index > 0 && beginDate && prevEndDate && prevEndDate.format('YYYY-MM-DD') > beginDate.format('YYYY-MM-DD')) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
      } else {
        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
      }
    }
    if (this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].valid) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
    }
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
    this.vesselDeploymentLines.updateValueAndValidity();
  }
  public CheckDateOnChanged(frmGrpControl, index) {
    this.CheckDuplicateDepLineWithPrevDepLines();
    this.checkMinStartDateAndEqualityForm(frmGrpControl, index, true);
  }

  // public checkDateEquality() {}  Method Merge in checkMinStartDateAndEquality
  // public checkPrevEndDateMin() {} Method Merge in checkMinStartDateAndEquality
  public isValidDateUpdateControlValue(control?, val?) {
    setTimeout(() => {
      control.setValue(val);
      control.updateValueAndValidity();
      this.vesselDeploymentLines.updateValueAndValidity();
    }, 100);
  }
  // Display Errors
  public displayErrorLines(deploymentFormGroup) {
    const deploymentLines =
      deploymentFormGroup.controls && deploymentFormGroup.controls.vesselDeploymentLines
        ? deploymentFormGroup.controls.vesselDeploymentLines.controls
        : [];
    this.showDeploymentFormError = deploymentFormGroup.status === 'VALID' ? false : true;
    this.deploymentErrorLines = [];
    if (deploymentLines) {
      for (const line in deploymentLines) {
        if (deploymentLines[line].status === 'INVALID' && !this.deploymentErrorLines.includes(Number(line) + 1)) {
          this.alertService.clear();
          if (Number(line) + 1 === deploymentLines.length) {
            // clear validators for last line
            deploymentLines[line].controls.depPosition.setErrors(null);
            this.vesselDeploymentFrmGrp.updateValueAndValidity();
          } else {
            this.deploymentErrorLines.push(Number(line) + 1);
          }
        }
      }
    }
  }
  public ResetDeploymentLineErrors() {
    this.errorInPrevDepLines = false;
    this.errorInPrevDepLinesMessage = null;
  }

  public ResetErrorForPastDeployments(frmGrpControl) {
    frmGrpControl.controls.depBeginDate.setErrors(null);
    frmGrpControl.controls.depEndDate.setErrors(null);
    frmGrpControl.controls.depStringID.setErrors(null);
    frmGrpControl.controls.depPosition.setErrors(null);
    frmGrpControl.controls.depReasonCodeID.setErrors(null);
    frmGrpControl.controls.depStartPortID.setErrors(null);
    frmGrpControl.controls.depEndPortID.setErrors(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
    frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
    frmGrpControl.updateValueAndValidity();
  }

  public populateDatePortForSpclPosition(frmGrpControl?, index?, isPastDepLine = false) {
    if (frmGrpControl) {
      const positionAtCurrentIndex = frmGrpControl.controls.depPosition.value;
    }
    // if (+positionAtCurrentIndex === 91 || +positionAtCurrentIndex === 92) {
    if (frmGrpControl && frmGrpControl.controls.isSpecialPosition) {
      if (index > 0) {
        const previousIndexDepLine = this.getValue(index - 1);
        // frmGrpControl.controls.depBeginDate.setValue(previousIndexDepLine.value.depEndDate);
        if (!isPastDepLine) {
          this.validateGapDeploymentLine(frmGrpControl, index);
        }
        const portsFromPrevDepLine = previousIndexDepLine.value.isSpecialPosition||previousIndexDepLine.value.isPastdepLine

          ? this.allPorts
          : this.depEndPortListLineWise[index - 1].ports;

        const portIdInPrevLine =
          previousIndexDepLine.value.depEndPortID || previousIndexDepLine.value.depSpecialServiceEndPortID;

        const portUsedInPrevLine = portIdInPrevLine
          ? portsFromPrevDepLine.filter((p) => p.schedulePortId === +portIdInPrevLine)
          : null;

        const filterPortForCurrentIndex = portUsedInPrevLine
          ? this.allPorts.filter((ap) =>
              ap.schedulePortId === portUsedInPrevLine[0]?.portId
                ? portUsedInPrevLine[0].schedulePortName.toLowerCase()
                : ''
            )
          : null;
        if (filterPortForCurrentIndex && filterPortForCurrentIndex[0]) {
          frmGrpControl.controls.depSpecialServiceStartPortID.setValue(filterPortForCurrentIndex[0].schedulePortId);
        }
      }

      this.SetEndPortSpecialPosition(index, frmGrpControl);
    }
    if (frmGrpControl) {
      frmGrpControl.controls.depStartPortID.setValue(null);
      frmGrpControl.controls.depEndPortID.setValue(null);
    }
  }
  public SetEndPortSpecialPosition(index: any, frmGrpControl: any) {
    const nextIndexDepLine = this.getValue(index + 1);

    if (nextIndexDepLine && nextIndexDepLine.value.depPosition) {
      const portsFromNextDepLine = nextIndexDepLine.value.isSpecialPosition
        ? this.allPorts
        : this.depStartPortListLineWise[index + 1].ports;

      const portIdInNextLine =
        nextIndexDepLine.value.depStartPortID || nextIndexDepLine.value.depSpecialServiceStartPortID;

      const portUsedInNextLine = portIdInNextLine
        ? portsFromNextDepLine.filter((p) => p.schedulePortId === +portIdInNextLine)
        : null;

      const filterPortForCurrentIndex =
        portUsedInNextLine && portUsedInNextLine[0]
          ? this.allPorts.filter((ap) => ap.schedulePortId === portUsedInNextLine[0].portId)
          : null;
      if (filterPortForCurrentIndex && filterPortForCurrentIndex[0]) {
        frmGrpControl.controls.depSpecialServiceEndPortID.setValue(filterPortForCurrentIndex[0].schedulePortId);
      }
    }
  }

  public populateDatePortForSpclPositionPastDates(frmGrpControl?, index?) {
    if (frmGrpControl && frmGrpControl.controls.isSpecialPosition) {
      if (index > 0) {
        const previousIndexDepLine = this.getValue(index - 1);
        const portIdInPrevLine = previousIndexDepLine.value.depPastEndPortId;
        if (portIdInPrevLine) {
          frmGrpControl.controls.depSpecialServiceStartPortID.setValue(portIdInPrevLine);
          frmGrpControl.controls.depPastStartPortId.setValue(portIdInPrevLine);
        }
      }
      const nextIndexDepLine = this.getValue(index + 1);

      if (nextIndexDepLine && nextIndexDepLine.value.depPosition && nextIndexDepLine.value.isPastdepLine) {
        const portIdInNextLine = nextIndexDepLine.value.depPastStartPortId;
        if (portIdInNextLine) {
          frmGrpControl.controls.depSpecialServiceEndPortID.setValue(portIdInNextLine);
          frmGrpControl.controls.depPastEndPortId.setValue(portIdInNextLine);
        }
      } else {
        this.SetEndPortSpecialPosition(index, frmGrpControl);
      }
    }
    if (frmGrpControl) {
      frmGrpControl.controls.depStartPortID.setValue(null);
      frmGrpControl.controls.depEndPortID.setValue(null);
    }
  }
  public validateGapDeploymentLine(control?, index?) {
    // control null check//

    if (index > 0) {
      const controlBeginDateNextDeployment = new Date(
        new DatePipe('en-US').transform(control.value.depBeginDate, 'dd-MMM-yy')
      );
      const lastDeployEndDate = new Date(
        new DatePipe('en-US').transform(this.vesselDeploymentLines.value[index - 1].depEndDate, 'dd-MMM-yy')
      );

      if (controlBeginDateNextDeployment.getTime() < lastDeployEndDate.getTime()) {
        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });

        return true;
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);

        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
      }
    } else {
      if (!this.fromCreateDummy || this.fromCreateDummy === undefined) {
        const controlBeginDateSameDeployment =
          control.value.depBeginDate !== null ? moment(control.value.depBeginDate).format('YYYY-MM-DD') : null;
        const controlEndDateSameDeployment =
          control.value.depEndDate !== null ? moment(control.value.depEndDate).format('YYYY-MM-DD') : null;
        if (controlBeginDateSameDeployment > controlEndDateSameDeployment) {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });

          return true;
        } else {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        }
      }
    }
  }
  // tslint:disable-next-line:use-life-cycle-interface
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // Editing single deployment line
  public verifyMultiplePrfExists(e?, frmGrpControl?, index?, isFunCall = false) {
    const isPastDepLine = this.IsPastdeploymentLine(frmGrpControl);
    // console.log('verifyMultiplePrfExists');
    if (isFunCall && frmGrpControl && isPastDepLine && frmGrpControl.controls.depPKID.value !== 0) {
      // this.vesselDeploymentLines.controls[index]['controls']['depIsUpdated'].setValue(true);
      this.isCancelShowPreviousClick = false;
      return;
    }
    this.portRangeErrorMsg = false;
    this.showWarningOverlapping = false;
    this.showErrorOverlapping = false;
    this.clearAlertService();

    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    if (
      frmGrpControl.controls.depStringID.value === null ||
      frmGrpControl.controls.depBeginDate.value === null ||
      frmGrpControl.controls.depEndDate.value === null ||
      frmGrpControl.controls.depPosition.value === null ||
      frmGrpControl.controls.depPosition.value === ''
    ) {
      return;
    }
    if (this.IsSpecialPositionChanged(frmGrpControl, frmGrpControl.controls.depPosition.value)) {
      return;
    }
    /** spinner starts */
    this.spinner.show();
    // this.controlsValueChanges(); => No Need to call

    this.showIncorrectDetailError = false;
    // setTimeout(() => {  => No Need to call Add timeout

    this.ifPosnEnteredIsSpcl = false;
    this.errorTextExist = false;
    this.matchedProformaLst = [];
    this.selectedServiceDtl = [];
    this.checkedProformaDtl = [];
    this.posPrfLst = [];
    this.posPrfStartEndDtLst = [];
    this.extraLoaderPosition = null;
    frmGrpControl.controls.extraPosition.setValue(false);
    this.depPositionValid = true;
    const isInValidPosition = this.ValidatePosition(frmGrpControl, index);
    if (isInValidPosition) {
      this.spinner.hide();
      return;
    }

    if (!frmGrpControl.controls.isSpecialService.value) {
      this.showScheduleDateError = false;
      // this.showEditPreviousWarning = false; => Not in Use
      // frmGrpControl.controls.depStartPortID.setValue(null); => setting already null in start of function
      //  frmGrpControl.controls.depEndPortID.setValue(null); => setting already null in start of function
      // frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null); => setting already null in start of function
      // //  frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null); => setting already null in start of function
      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      const serviceId = frmGrpControl.controls.depStringID.value || 0;
      const positionEntered = this.validateRegExPosPattern(frmGrpControl)
        ? this.extraLoaderPosition
        : frmGrpControl.controls.depPosition.value;

      this.vesselsService
        .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
        // .pipe(delay(100))
        .subscribe(async (response: any) => {
          this.vesselDeploymentDropdowns = response;
          const vesselDeploymentServices = this.vesselDeploymentDropdowns.services.map((o) => {
            return {
              strPKID: o.strPKID,
              strCode: o.strCode,
              strName: o.strName,
              strRoundTrip: o.strRoundTrip,
              strTrade: o.strTrade,
              isSpecial: o.isSpecial,
              strNameCode: o.strCode + '  ' + o.strName,
              positions: o.positions,
              positionMaxDateByProforma: o.positionMaxDateByProforma,
            };
          });
          this.servicePositionsDetailsLst = vesselDeploymentServices;
          //const oldPrevStateIsSpecial = frmGrpControl.controls.isSpecialPosition.value;
          const lstOfSpecialPositions = await response.specialPositionsList;
          this.lstofServicesPositions =
            response.services[0].positions !== null ? await response.services[0].positions : null;
          if (lstOfSpecialPositions.length) {
            this.ifPosnEnteredIsSpcl =
              lstOfSpecialPositions.findIndex((element) => element.spPosition === +positionEntered) > -1 ? true : false;
            if (!this.ifPosnEnteredIsSpcl) {
              frmGrpControl.controls.isSpecialPosition.setValue(false);
            } else {
              frmGrpControl.controls.isSpecialPosition.setValue(true);
            }
          }
          // // CHAM-1748 - No reloading ports on update
          // if (frmGrpControl.controls.depPKID.value !== 0 && this.ifPosnEnteredIsSpcl && oldPrevStateIsSpecial) {
          //   // If position is Special and its an update call then return it
          //   this.spinner.hide();
          //   return;
          // } else {
          this.ResetPortAndServiceDepLines(frmGrpControl, this.ifPosnEnteredIsSpcl);
          // }
          let ifPosEnteredIsValid;
          if (
            this.lstofServicesPositions &&
            this.lstofServicesPositions.length > 0 &&
            !frmGrpControl.controls.isSpecialPosition.value
          ) {
            if (this.lstofServicesPositions.length) {
              ifPosEnteredIsValid =
                this.lstofServicesPositions.findIndex((element) => element.position === +positionEntered) > -1
                  ? true
                  : false;
              if (!ifPosEnteredIsValid) {
                if (!isPastDepLine) {
                  frmGrpControl.controls['depPosition'].setErrors({ incorrect: true });
                  this.displayErrorLines(this.vesselDeploymentFrmGrp);
                }
                this.spinner.hide();
                return;
              } else {
                frmGrpControl.controls['depPosition'].setErrors(null);
              }
            }
          }
          this.CheckPositionMaxDateByProforma(frmGrpControl);
          this.spinner.hide();
          this.CheckMultiProforma(frmGrpControl, e, index);
        });
    } else {
      frmGrpControl.controls.depStartPortID.setErrors(false);
      frmGrpControl.controls.depEndPortID.setErrors(false);
      this.populateReasonDetails(e, frmGrpControl, index, '');
    }
    // }, 1000);
  }
  public IsSpecialPositionChanged(frmGrpControl, positionEntered) {
    const oldPrevStateIsSpecial = frmGrpControl.controls.isSpecialPosition.value;

    if (!positionEntered || !oldPrevStateIsSpecial) {
      return false;
    }
    if (this.vesselDeploymentSpclServices.length) {
      this.ifPosnEnteredIsSpcl =
        this.vesselDeploymentSpclServices.findIndex((element) => element.spPosition === +positionEntered) > -1
          ? true
          : false;
      if (!this.ifPosnEnteredIsSpcl) {
        frmGrpControl.controls.isSpecialPosition.setValue(false);
      } else {
        frmGrpControl.controls.isSpecialPosition.setValue(true);
      }
    }

    // CHAM-1748 - No reloading ports on update
    if (frmGrpControl.controls.depPKID.value !== 0 && this.ifPosnEnteredIsSpcl && oldPrevStateIsSpecial) {
      // If position is Special and its an update call then return it
      this.spinner.hide();
      return true;
    } else {
      return false;
    }
  }

  public ValidatePosition(frmGrpControl, index) {
    // position validation
    const pos = frmGrpControl.controls.depPosition.value;
    const pospatternMatched = this.validatePositionPattern(pos);
    if (!pospatternMatched) {
      this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });

      return true;
    }

    if (!frmGrpControl.controls.isSpecialService.value) {
      this.showScheduleDateError = false;
      this.validateDepPosition(frmGrpControl);
      // Added restriction when position is invalid
      if (!this.depPositionValid) {
        // Added Validation check for invalid position
        frmGrpControl.controls['depPosition'].setErrors({ incorrect: true });
        this.displayErrorLines(this.vesselDeploymentFrmGrp);
        this.spinner.hide();
        return true;
      }
    }
  }
  public CheckPositionMaxDateByProforma(frmGrpControl) {
    if (this.servicePositionsDetailsLst[0].positionMaxDateByProforma != null) {
      const positionSelected = this.ValidateExtraPositionSelection(frmGrpControl);
      this.servicePositionsDetailsLst[0].positionMaxDateByProforma.map((item) => {
        const minDate = new Date(new DatePipe('en-US').transform(item.minDate, 'dd-MMM-yyyy'));
        const maxDate = new Date(new DatePipe('en-US').transform(item.maxDate, 'dd-MMM-yyyy'));
        const depBegin = new Date(
          new DatePipe('en-US').transform(frmGrpControl.controls.depBeginDate.value, 'dd-MMM-yyyy')
        );
        const depEnd = new Date(
          new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
        );
        if (
          +item.position === +positionSelected &&
          minDate.getTime() <= depBegin.getTime() &&
          maxDate.getTime() >= depEnd.getTime()
        ) {
          this.posPrfLst.push(item);
        }
        if (+item.position === +positionSelected) {
          this.posPrfStartEndDtLst.push(item);
        }
      });
      this.vesselDeploymentServices.map((item) => {
        if (item.strPKID === frmGrpControl.controls.depStringID.value) {
          item.lstProformas.map((o) => {
            this.posPrfLst.map((k) => {
              if (k.proformaID === o.proformaID) {
                this.matchedProformaLst.push(o);
              }
            });
          });
          this.selectedServiceDtl = item;
        }
      });
    }
  }
  public CheckMultiProforma(frmGrpControl, e, index) {
    if (this.matchedProformaLst.length > 1) {
      // console.log(frmGrpControl);
      if (frmGrpControl.controls[`depProformaID`].value) {
        this.CurrentDepselectedProformaID = frmGrpControl.controls[`depProformaID`].value;
      } else {
        this.CurrentDepselectedProformaID = null;
      }
      // console.log(this.CurrentDepselectedProformaID);

      this.eventDtl = e;
      this.frmGrpControlDtl = frmGrpControl;
      this.indexDtl = index;
      this.dialogRef = this.dialog.open(this.selectProformaDialog, {
        width: '580px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    } else if (this.matchedProformaLst.length === 1) {
      this.populateReasonDetails(e, frmGrpControl, index, this.matchedProformaLst[0]);
    } else {
      this.populateReasonDetails(e, frmGrpControl, index, '');
    }
  }

  public validateDepPosition(frmGrpControl?) {
    this.depPositionExists = true;
    this.extraPosEntered = false;
    this.showError = false;
    const patternMatched = this.validateRegExPosPattern(frmGrpControl);
    if (patternMatched) {
      this.extraPosEntered = true;
      this.extraLoaderPosition = frmGrpControl.controls.depPosition.value;
      // For extra slice x from the input and check min max value validation
      this.extraLoaderPosition = this.extraLoaderPosition.toLowerCase();
      const xPos = this.extraLoaderPosition.indexOf('x');
      xPos === 1
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
        : xPos === 2
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
        : (this.extraLoaderPosition = null);

      if (+this.extraLoaderPosition >= 0 && +this.extraLoaderPosition <= 99) {
        this.depPositionValid = true;

        this.validatePosExists(this.extraLoaderPosition, true, frmGrpControl);
      } else {
        this.depPositionValid = false;
        this.showError = true;
      }
    } else if (patternMatched === false) {
      if (+frmGrpControl.controls.depPosition.value >= 0 && +frmGrpControl.controls.depPosition.value <= 99) {
        this.depPositionValid = true;
        this.validatePosExists(frmGrpControl.controls.depPosition.value, false, frmGrpControl);
      } else {
        this.depPositionValid = false;
        this.showError = true;
      }
    }
  }
  // Refractoring Required for below method
  public populateReasonDetails(e?, frmGrpControl?, index?, checkedProformaDtl?) {
    this.spinner.show();
    const isPastDepLine = this.IsPastdeploymentLine(frmGrpControl);
    if (!frmGrpControl.controls.isSpecialService.value) {
      this.showScheduleDateError = false;
      const strDate = frmGrpControl.controls.depBeginDate.value
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;

      const endDate = frmGrpControl.controls.depEndDate.value
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

      const selectedService = frmGrpControl.controls.depStringID.value;
      const selectedEndDateTime = endDate != null ? endDate + 'T' + '00:00' : null;
      const selectedDateTime = strDate + 'T' + '00:00';
      const selectedposition = frmGrpControl.controls.depPosition.value;
      // Error handling Removing ifPosEnteredIsValid for a while
      if (!frmGrpControl.controls.isSpecialPosition.value) {
        if (selectedService && selectedDateTime) {
          this.vesselsService
            .getPortsByServiceByPosition(selectedService, selectedDateTime, selectedEndDateTime, selectedposition)
            // .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe(
              (response: any) => {

                this.allPortsByService =  response;

                const positionSelected = this.ValidateExtraPositionSelection(frmGrpControl);
                // End of code
                const startportSet1 = new Set();
                const endportSet1 = new Set();

                if (positionSelected && positionSelected > 0 && !frmGrpControl.controls.isSpecialPosition.value) {
                  this.populateReasonDetails_PositionisNotSpecial(
                    checkedProformaDtl,
                    response,
                    positionSelected,
                    index,
                    startportSet1,
                    endportSet1,
                    frmGrpControl,
                    isPastDepLine
                  );
                  if (!isPastDepLine) {
                    this.validateEndDateErrHandlng(e, frmGrpControl, index);
                    this.validateStartDateErrHandlng(e, frmGrpControl, index);
                  }
                  this.checkAdjacentLinesForSpcl(frmGrpControl, index);
                } else {
                  this.lpopulateReasonDetails_isSpecial(response, index, startportSet1, endportSet1);
                  if (!isPastDepLine) {
                    this.validateEndDateErrHandlng(e, frmGrpControl, index);
                    this.validateStartDateErrHandlng(e, frmGrpControl, index);
                  }
                }
                // this.checkMinStartDate();
                // this.checkDateEquality();
                if (!isPastDepLine) {
                  // this.checkMinStartDateAndEquality();
                  this.checkMinStartDateAndEqualityForm(frmGrpControl, index, false);
                  this.vesselDeploymentFrmGrp.updateValueAndValidity();
                }
                frmGrpControl.controls['depEndPortID'].setErrors(null);
                frmGrpControl.controls['depEndDate'].setErrors(null);

                this.spinner.hide();
              },
              (errorResponse) => {
                this.ErrorResponse_populateReasonDetails_Handler(errorResponse, index, frmGrpControl);
              }
            );
          // Move this part inside Subscription
          // frmGrpControl.controls['depEndPortID'].setErrors(null);
          // frmGrpControl.controls['depEndDate'].setErrors(null);
          // this.validateEndDateErrHandlng(e, frmGrpControl, index);
          // this.validateStartDateErrHandlng(e, frmGrpControl, index);
        }
      } else if (this.ifPosnEnteredIsSpcl && frmGrpControl.controls.isSpecialPosition.value) {
        frmGrpControl.controls.depStartPortID.clearValidators();
        frmGrpControl.controls.depEndPortID.clearValidators();
        frmGrpControl.updateValueAndValidity();
        // frmGrpControl.controls.depEndPortID.updateValueAndValidity();
        frmGrpControl.controls.depStartPortID.setErrors({ required: false });
        frmGrpControl.controls.depEndPortID.setErrors({ required: false });
        this.loadPorts(frmGrpControl, index);
        // this.populateDatePortForSpclPosition(frmGrpControl, index);
      }
    } else if (frmGrpControl.controls.isSpecialService.value) {
      this.LoadWhenServiceIsSpecial(e, frmGrpControl, index, isPastDepLine);
    }

    if (this.fromCreateDummy && index === 0) {
      if (e !== '') {
        this.getPositionChangeValforDummy.emit(e);
      } else {
        this.getPositionChangeValforDummy.emit({ event: e, depPosition: frmGrpControl.controls.depPosition.value });
      }
    }
    if (!isPastDepLine) {
      // Error Handling to Validate Position
      if (
        this.depPositionExists === false ||
        !this.depPositionValid ||
        this.vesselDeploymentLines.controls[index]['controls']['depPosition'].value === ''
      ) {
        this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors(null);
      }
      this.validateEndDateErrHandlng(e, frmGrpControl, index);
      this.validateStartDateErrHandlng(e, frmGrpControl, index);

      // To enable or disable Save button on start date click
      if (frmGrpControl.controls.depStartPortID.value == null) {
        frmGrpControl.setErrors({ invalid: true });
      }
      if (frmGrpControl.controls.depEndPortID.value == null) {
        frmGrpControl.setErrors({ invalid: true });
      }
    }
    this.spinner.hide();
  }
  public populateReasonDetails_PositionisNotSpecial(
    checkedProformaDtl,
    response,
    positionSelected,
    index,
    startportSet1,
    endportSet1,
    frmGrpControl,
    isPastDepLine
  ) {
    this.isVesslDeplyPositionSelected = true;
    checkedProformaDtl
      ? (this.vesselDeploymentStartPort = response.portDateTime.filter(
          (o) => o.position === positionSelected && o.scheduleProformaPKId === checkedProformaDtl.proformaPKID
        ))
      : (this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.position === positionSelected));
    // for multiple proforma and duplicate port
    let multipleProformaMinPKId;
    this.depStartPortListLineWise[index] = {};

    if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
      const mindateprfID = this.posPrfStartEndDtLst.reduce(function (r, a) {
        return r.minDate < a.minDate ? r : a;
      });

      multipleProformaMinPKId = mindateprfID.proformaPKID;

      this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
        (o) =>
          !startportSet1.has(o.schedulePortId) &&
          startportSet1.add(o.schedulePortId) &&
          o.scheduleProformaPKId === multipleProformaMinPKId
      );

      this.isMultipleProformasflag = true;
    } else {
      this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
        (o) => !startportSet1.has(o.schedulePortId) && startportSet1.add(o.schedulePortId)
      );
    }

    checkedProformaDtl
      ? (this.vesselDeploymentEndPort = response.portDateTime.filter(
          (o) => o.position === positionSelected && o.scheduleProformaPKId === checkedProformaDtl.proformaPKID
        ))
      : (this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.position === positionSelected));

    let multipleProformaMaxPKId;
    if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
      const maxdateprfID = this.posPrfStartEndDtLst.reduce(function (r, a) {
        return r.maxDate > a.maxDate ? r : a;
      });

      multipleProformaMaxPKId = maxdateprfID.proformaPKID;

      this.depEndPortListLineWise[index] = {};
      this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
        (o1) =>
          !endportSet1.has(o1.schedulePortId) &&
          endportSet1.add(o1.schedulePortId) &&
          o1.scheduleProformaPKId === multipleProformaMaxPKId
      );

      this.isMultipleProformasflag = true;
    } else {
      this.depEndPortListLineWise[index] = {};
      this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
        (o1) => !endportSet1.has(o1.schedulePortId) && endportSet1.add(o1.schedulePortId)
      );
    }

    let startPortToPopulate: any;
    const startDateBeforePort = new Date(
      new DatePipe('en-US').transform(frmGrpControl.controls.depBeginDate.value, 'dd-MMM-yyyy')
    );

    if (this.isMultipleProformasflag) {
      checkedProformaDtl
        ? (startPortToPopulate = response.portDateTime.filter(
            (k) =>
              k.position === positionSelected &&
              k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
              startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
          ))
        : (startPortToPopulate = response.portDateTime.filter(
            (k) =>
              k.position === positionSelected &&
              k.scheduleProformaPKId === multipleProformaMinPKId &&
              startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
          ));
    } else {
      checkedProformaDtl
        ? (startPortToPopulate = response.portDateTime.filter(
            (k) =>
              k.position === positionSelected &&
              k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
              startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
          ))
        : (startPortToPopulate = response.portDateTime.filter(
            (k) =>
              k.position === positionSelected &&
              startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
          ));
    }

    // populate Start Port
    if (startPortToPopulate.length) {
      if (isPastDepLine) {
        frmGrpControl.controls.isSpecialPosition.setValue(true);
        frmGrpControl.controls.depSpecialServiceStartPortID.setValue(startPortToPopulate[0].portId);
        frmGrpControl.controls.depPastStartPortId.setValue(startPortToPopulate[0].portId);
        frmGrpControl.controls.depStartPortID.setValue(null);
      } else {
        frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
        this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;
        frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
        frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
      }
      if (this.fromCreateDummy && index === 0) {
        this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
      }
    }
    let endPortToPopulate: any;
    const endDateBeforePort = new Date(
      new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
    );
    if (endDateBeforePort) {
      if (this.isMultipleProformasflag) {
        checkedProformaDtl
          ? (endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
            ))
          : (endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
                k.scheduleProformaPKId === multipleProformaMaxPKId
            ));
      } else {
        checkedProformaDtl
          ? (endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
            ))
          : (endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
            ));
      }

      if (endPortToPopulate.length) {
        if (isPastDepLine) {
          frmGrpControl.controls.isSpecialPosition.setValue(true);
          frmGrpControl.controls.depSpecialServiceEndPortID.setValue(
            endPortToPopulate[endPortToPopulate.length - 1].portId
          );
          frmGrpControl.controls.depPastEndPortId.setValue(endPortToPopulate[endPortToPopulate.length - 1].portId);
          frmGrpControl.controls.depEndPortID.setValue(null);
        } else {
          frmGrpControl.controls.depEndPortID.setValue(endPortToPopulate[endPortToPopulate.length - 1].schedulePortId);
          this.schedulePrfEndPortPKId = endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;

          frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
          frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
        }
      }
    }
  }
  public lpopulateReasonDetails_isSpecial(response, index, startportSet1, endportSet1) {
    this.isVesslDeplyPositionSelected = false;
    this.vesselDeploymentStartPort = response.portDateTime;
    this.depStartPortListLineWise[index] = {};
    this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
      (o) => !startportSet1.has(o.schedulePortId) && startportSet1.add(o.schedulePortId)
    );
    this.vesselDeploymentEndPort = response.portDateTime;
    this.depEndPortListLineWise[index] = {};
    this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
      (o1) => !endportSet1.has(o1.schedulePortId) && endportSet1.add(o1.schedulePortId)
    );
  }

  public LoadWhenServiceIsSpecial(e, frmGrpControl, index, ispastDep = false) {
    frmGrpControl.controls.isSpecialPosition.setValue(true);
    if (ispastDep) {
      this.depPositionValid = true;
      frmGrpControl.controls['depPosition'].setErrors(null);
      frmGrpControl.updateValueAndValidity();
    } else {
      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      const serviceId = frmGrpControl.controls.depStringID.value || 0;
      const positionEntered = frmGrpControl.controls.depPosition.value;
      this.spinner.show();
      // if (vesselId && serviceId) {
      this.vesselsService
        .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
        .subscribe(async (response: any) => {
          const lstOfSpecialPositions = await response.specialPositionsList;

          if (lstOfSpecialPositions.length) {
            const ifPosEnteredIsSpcl =
              lstOfSpecialPositions.findIndex((element) => element.spPosition === +positionEntered) > -1 ? true : false;

            if (!ifPosEnteredIsSpcl) {
              this.depPositionValid = false;

              this.showError = true;
              frmGrpControl.controls['depPosition'].setErrors({ incorrect: true });
              this.ResetPortsWhenServiceIsSpecial(frmGrpControl);
            } else {
              this.depPositionValid = true;
              frmGrpControl.controls['depPosition'].setErrors(null);
              frmGrpControl.updateValueAndValidity();
              // CHAM-1748 - No reloading ports on update
              if (
                frmGrpControl.controls.depPKID.value !== 0 &&
                frmGrpControl.controls.depSpecialServiceStartPortID.value &&
                frmGrpControl.controls.depSpecialServiceEndPortID.value
              ) {
                // If position is Special and its an update call then return it
                this.spinner.hide();
                return;
              } else {
                this.ResetPortsWhenServiceIsSpecial(frmGrpControl);
              }
              this.loadPorts(frmGrpControl, index);
            }
          }
          if (!ispastDep) {
            this.validateEndDateErrHandlng(e, frmGrpControl, index);
            this.validateStartDateErrHandlng(e, frmGrpControl, index);
          }
        });
    }
    // }
  }
  public ResetPortsWhenServiceIsSpecial(frmGrpControl) {
    frmGrpControl.controls.depStartPortID.setValue(null);
    frmGrpControl.controls.depEndPortID.setValue(null);
    frmGrpControl.controls.depStartPortID.setErrors(false);
    frmGrpControl.controls.depEndPortID.setErrors(false);
    frmGrpControl.controls.depStartPortID.clearValidators();
    frmGrpControl.controls.depEndPortID.clearValidators();

    frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null);

    frmGrpControl.updateValueAndValidity();
  }
  public ErrorResponse_populateReasonDetails_Handler(errorResponse, index, frmGrpControl) {
    this.HandleSchedulesNotExistForPastDates(errorResponse, index, frmGrpControl);
    // this.showScheduleDateError = true;
    // const msg = errorResponse.error.error;
    // this.scheduleDateErrorMsg = msg;
    // this.depEndPortListLineWise[index] = {};
    // this.depEndPortListLineWise[index].ports = [];
    // this.depStartPortListLineWise[index] = {};
    // this.depStartPortListLineWise[index].ports = [];
    // // Error handling for end port and end date when schedule doesn;t exist.
    // frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
    // frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
    // // this.checkMinStartDate();
    // // this.checkDateEquality();
    // // this.checkMinStartDateAndEquality();
    this.checkMinStartDateAndEqualityForm(frmGrpControl, index, false);
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
    this.spinner.hide();
  }
  public selectedProformaDtl() {
    this.matchingPrfrmSelectedDtl = [];
    this.errorTextExist = false;
    if (this.checkedProformaDtl.length !== 0) {
      this.frmGrpControlDtl.controls[`depProformaID`].value = this.checkedProformaDtl.proformaID;
      this.populateReasonDetails(this.eventDtl, this.frmGrpControlDtl, this.indexDtl, this.checkedProformaDtl);
      this.dialogRef.close();
    } else {
      this.errorTextExist = true;
      this.errorText = 'Please select one record.';
    }
  }
  public proformaChanged(e) {
    if (e && e.value) {
      this.errorTextExist = false;
      this.checkedProformaDtl = e.value;
    }
  }
  public validatePosExists(positionEntered, extra?, frmGrpControl?) {
    this.validExtraPos = false;
    // Below commneted code we are not using
    // const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
    // const serviceId = frmGrpControl.controls.depStringID.value || 0;

    if (extra === false) {
      if (+positionEntered === 0 || +positionEntered === 31 || +positionEntered === 99) {
        this.depPositionExists = true;
      }
      this.depPositionExists = true;
    } else if (extra === true) {
      const specialPos = ['0', '31', '99'];
      if (specialPos.indexOf('' + positionEntered) === -1) {
        this.validExtraPos = true;
        frmGrpControl.controls.depPosition.setValue(positionEntered + 'X');
        frmGrpControl.controls.extraPosition.setValue(this.validExtraPos ? true : false);
      } else {
        this.depPositionExists = false;
        frmGrpControl.controls.depPosition.setValue(positionEntered);
        frmGrpControl.controls.extraPosition.setValue(false);
        this.showError = true;
      }
    } else {
      this.depPositionExists = false;
      this.showError = true;
    }
    // });
  }
  public checkAdjacentLinesForSpcl(frmGrpControl?, index?) {
    if (index > 0) {
      const prevLine = this.getValue(index - 1);
      if (prevLine.value.isSpecialPosition) {
        const startPortInCurrentLine = this.depStartPortListLineWise[index].ports.filter(
          (p) => p.schedulePortId === +frmGrpControl.controls.depStartPortID.value
        );
        const portForPrevSpclLine = startPortInCurrentLine.length
          ? this.allPorts.filter((ap) => ap.schedulePortId === startPortInCurrentLine[0].portId)
          : null;
        if (portForPrevSpclLine && portForPrevSpclLine.length) {
          prevLine['controls'].depSpecialServiceEndPortID.setValue(portForPrevSpclLine[0].schedulePortId);
        }
      }
    }
    const nextLine = this.getValue(index + 1);
    if (nextLine && nextLine.value.isSpecialPosition) {
      const endPortInCurrentLine = this.depEndPortListLineWise[index].ports.filter(
        (p) => p.schedulePortId === +frmGrpControl.controls.depEndPortID.value
      );
      const portForNextSpclLine = endPortInCurrentLine.length
        ? this.allPorts.filter((ap) => ap.schedulePortId === endPortInCurrentLine[0].portId)
        : null;
      if (endPortInCurrentLine && endPortInCurrentLine.length) {
        nextLine['controls'].depSpecialServiceStartPortID.setValue(portForNextSpclLine[0].schedulePortId);
      }
    }
  }
  public getValue(i?) {
    return this.getControls()[i];
  }
  public getControls() {
    return (<UntypedFormArray>this.vesselDeploymentFrmGrp.get('vesselDeploymentLines')).controls;
  }
  public clearAlertService() {
    this.alertService.clear();
    this.alertService.alertVesselId.next('');
    this.showAlertForVesselId = '';
    this.preventSave = false;
    this.warnings = [];
    this.overlappingErrorMsg = [];
    this.incorrectDeploymentDetails = '';
    this.incorrectDeploymentDetailsNew = '';
    this.showPortRangeError = false;
    this.overlappingMsg = [];
    this.trade_restrictions = [];
    this.showErrorOverlapping = false;
    this.showWarningOverlapping = false;
    this.changeButtonState();
    this.ResetDeploymentLineErrors();
  }
  public changeButtonState() {
    (this.showWarningOverlapping || this.showPortRangeError || this.warnings.length) && !this.showErrorOverlapping
      ? this.vesselsService.saveButtonState.next(true)
      : this.vesselsService.saveButtonState.next(false);
  }
  public displayDetails(errorResponse) {
    if (errorResponse.error.code === 'VesselDeploymentOverlapWarning') {
      this.overlappingExist = true;
      this.showIncorrectDetailError = true;
      if (errorResponse.error.data.inRange_warnings) {
        this.showPortRangeError = true;
        const msgRange = errorResponse.error.data.inRange_warnings;
        const msg = 'Redelivery NOT in range - ' + msgRange.CityName + ' does not match redelivery range under fixture';
        this.fixtureIdForRange = msgRange.fixPKID;
        this.fixtureContractNo = msgRange.fixContractNo;
        this.portRangeErrorMsg = msg;
      }
      if (errorResponse.error.data.overlap_warnings && errorResponse.error.data.overlap_warnings.length) {
        this.showWarningOverlapping = true;
        const msg = errorResponse.error.data.overlap_warnings
          ? errorResponse.error.data.overlap_warnings
          : errorResponse.error;
        this.overlappingMsg = msg;
      }
      if (errorResponse.error.data.warnings) {
        this.showWarningOverlapping = true;
        this.warnings = errorResponse.error.data.warnings;
      }
      if (errorResponse.error.data.trade_restrictions) {
        this.showWarningOverlapping = true;
        this.trade_restrictions = errorResponse.error.data.trade_restrictions;
      }
      if (errorResponse.error.data.error_data && errorResponse.error.data.error_data.length) {
        this.showErrorOverlapping = true;
        this.preventSave = true;
        const extraPos = errorResponse.error.data.error_data[0].extra_position ? true : false;

        const warnMessageArray = [];
        for (let i = 0; i < errorResponse.error.data.error_data.length; i++) {
          const warnMessage = {
            vessel_status_id_p: errorResponse.error.data.error_data[i].p_vessel_status_id,
            vslPKID_p: errorResponse.error.data.error_data[i].p_vslPKID,
            vessel_status_id_s: errorResponse.error.data.error_data[i].s_vessel_status_id,
            vslPKID_s: errorResponse.error.data.error_data[i].s_vslPKID,
            vessel_name_primary: errorResponse.error.data.error_data[i].p_vessel_name,
            vessel_name_secondary: errorResponse.error.data.error_data[i].s_vessel_name,
            position: errorResponse.error.data.error_data[i].position,
            route_code: errorResponse.error.data.error_data[i].route_code,
            service_code: errorResponse.error.data.error_data[i].service_code,
            service_name: errorResponse.error.data.error_data[i].service_name,
            // overlapping_start_date:  new DatePipe('en-US').transform(errorResponse.error.data.error_data[i].overlapping_start_date, 'dd-MMM-yyyy'),
            // overlapping_end_date:  new DatePipe('en-US').transform(errorResponse.error.data.error_data[i].overlapping_end_date, 'dd-MMM-yyyy'),
            p_overlapping_start_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].p_overlapping_start_date,
              'dd-MMM-yyyy'
            ),
            p_overlapping_end_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].p_overlapping_end_date,
              'dd-MMM-yyyy'
            ),
            s_overlapping_start_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].s_overlapping_start_date,
              'dd-MMM-yyyy'
            ),
            s_overlapping_end_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].s_overlapping_end_date,
              'dd-MMM-yyyy'
            ),
            extra_position: extraPos,
          };
          warnMessageArray.push(warnMessage);
        }
        if (errorResponse.error.data.error_data.length >= 1) {
          this.incorrectDeploymentDetailsNew =
            'Deployment CANNOT be saved. There are multiple vessels overlappingly deployed on same dates.';
        }
        this.overlappingErrorMsg = warnMessageArray;
        if (!this.isOwnDeploymentType) {
          return;
        }
      }
    } else {
      this.showErrorOverlapping = true;
      this.showWarningOverlapping = false;
      this.showIncorrectDetailError = true;
      const msg = errorResponse.error.error;
      this.overlappingErrorMsg = msg;
    }
    this.changeButtonState();
  }
  public HandleSchedulesNotExistForPastDates(errorResponse, index, frmGrpControl) {
    const isPastdepLine = this.IsPastdeploymentLine(frmGrpControl);
    if (isPastdepLine) {
      // Future use In case past Dates if we need to hangle Schedules not found
    } else {
      // Error handling for end port and end date when schedule doesn;t exist.
      this.showScheduleDateError = true;
      const msg = errorResponse.error.error;
      this.scheduleDateErrorMsg = msg;
      this.depEndPortListLineWise[index] = {};
      this.depEndPortListLineWise[index].ports = [];
      this.depStartPortListLineWise[index] = {};
      this.depStartPortListLineWise[index].ports = [];
      frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
      frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
    }
    this.spinner.hide();
  }

  // Private Methods
  private ResetPortAndServiceDepLines(frmGrpControl: any, isSpecialPos) {
    frmGrpControl.controls.isSpecialPosition.setValue(isSpecialPos);
    frmGrpControl.controls.depStartPortID.setValue(null);
    frmGrpControl.controls.depEndPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
    frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
  }

  private validateRegExPosPattern(frmGrpControl?) {
    return /^(?:(^([1-9][\d]*){1,2})+([xX])$)/g.test(frmGrpControl.controls.depPosition.value);
  }
  private validatePositionPattern(posvalue?) {
    return /^0*([1-9]){1,2}\d?[xX]?$/g.test(posvalue);
  }
  private SetLastYearDeploymentLines() {
    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe(async (controlValue) => {
      if (controlValue) {
        // Todo : do we really need to 2 objects=>lastYearVesselPreviousDeployments & lastYearVesselPreviousDeploymentsObj
        this.lastYearVesselPreviousDeployments = await this.lastYearPreviousDeployments(controlValue);
        this.lastYearVesselPreviousDeploymentsObj = this.lastYearVesselPreviousDeployments
          ? JSON.parse(JSON.stringify(this.lastYearVesselPreviousDeployments))
          : null;
      }
    });
    // Todo : do we really need to 2 objects=> veslDeployments  & veslDeploymentsObj
    // and in toogleEditMode   we assigning again so no need of below Code
    // if (typeof this.veslDeployments !== 'undefined') {
    //   this.veslDeploymentsObj = JSON.parse(JSON.stringify(this.veslDeployments));
    // }
  }
  private ValidateLoadPorts(frmGrpControl: any, index: any, isPastDepLine = false) {
    if (isPastDepLine) {
      this.populateDatePortForSpclPositionPastDates(frmGrpControl, index);
    } else {
      this.populateDatePortForSpclPosition(frmGrpControl, index, isPastDepLine);
    }

    if (!isPastDepLine) {
      // this.checkMinStartDateAndEquality();
      this.checkMinStartDateAndEqualityForm(frmGrpControl, index, true);
      this.validateEndDateErrHandlng('', frmGrpControl, index);
      this.validateStartDateErrHandlng('', frmGrpControl, index);
    }
  }
  private ValidateExtraPositionSelection(frmGrpControl: any) {
    const patternMatched = this.validateRegExPosPattern(frmGrpControl);
    let positionSelected;
    if (patternMatched) {
      const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
      const xPos = extraPosValue.indexOf('x');
      xPos === 1
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
        : xPos === 2
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
        : (this.extraLoaderPosition = null);
      positionSelected = this.extraLoaderPosition;
    } else {
      positionSelected = +frmGrpControl.controls.depPosition.value;
    }
    return positionSelected;
  }
  private SetDeploymentData() {
    this.veslDeployments = [
      ...this.depItems.deployments.currentDeployment,
      ...this.depItems.deployments.upcomingDeployment,
    ].filter((depLines) => !depLines.depIsDeleted);
    this.veselID = this.depItems.vcPKID;
    this.veselType = this.depItems.vcType;
    this.numberOfvslDeploymentLine = this.veslDeployments.length;
    // Contains Current deployment lines
    this.vesselCurrentDeployment =
      this.depItems.deployments.currentDeployment && this.depItems.deployments.currentDeployment.length
        ? this.depItems.deployments.currentDeployment
        : null;
    // Contains Upcoming deployment lines
    this.vesselUpcomingDeployment =
      this.depItems.deployments.upcomingDeployment && this.depItems.deployments.upcomingDeployment.length
        ? this.depItems.deployments.upcomingDeployment
        : null;
    if (this.vesselUpcomingDeployment != null) {
      this.vesselUpcomingDeployment.map((e) => {
        const valUpcomming = e.maerskAllocationOnServiceDto;
        if (valUpcomming != null) {
          if (
            valUpcomming.mlmtCap === 0 ||
            valUpcomming.mlTeuCap === 0 ||
            valUpcomming.totalMTCap === 0 ||
            valUpcomming.totalTeuCap === 0
          ) {
            e.vsaFlagUp = ' ';
          } else {
            const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
            const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
            if (+teuPercent < 100 || +mlPercent < 100) {
              e.vsaFlagUp = '(VSA) ';
            } else {
              e.vsaFlagUp = ' ';
            }
          }
        }
      });
    }
    // Contains Previous deployment lines
    this.vesselPreviousDeployments =
      this.depItems.deployments.previousDeployment && this.depItems.deployments.previousDeployment.length
        ? this.depItems.deployments.previousDeployment
        : null;

    if (this.veslDeployments && this.isVsDepUpdateAuth) {
      if (this.isPostFixutreUserAuthorized) {
        this.editDeployments = true;
        this.toogleEditMode();
      } else {
        this.loadDefaultEditMode();
      }
    } else {
      this.editDeployments = true;
      this.toogleEditMode();
    }
  }
}
